import { ActionCreator } from 'redux'
import get from 'lodash/get'
import { unregister } from '../../../registerServiceWorker'
import { countdownTime } from '../../../utils/constants'
import { SortMode } from '../../../utils/constants/enum'
import { AsyncAction, Dispatch, GetState } from '../../../utils/redux/thunk'

import { LoadMode, UpdateMode } from '../../entities/sortMode/actionNames'
import { createLoadAction, createUpdateAction, doMissedConnectionMode } from '../../entities/sortMode/actions'

import * as fromOverview from '.'
import { REFRESH_DATA, RefreshData, SET_COUNTDOWN, SetCountdown } from './actionNames'

export const createSetActiveCountdownAction: ActionCreator<SetCountdown> = (
  enabled: boolean,
) => ({
  enabled, type: SET_COUNTDOWN,
})

export const createUpdateModeAction: ActionCreator<AsyncAction<UpdateMode>> =
  (targetMode: SortMode) => (dispatch: Dispatch) => {
    if (get(targetMode, 'mode') === SortMode.missedConnection) {
      dispatch(doMissedConnectionMode(targetMode))
    } else {
      dispatch(createUpdateAction(targetMode))
    }
    dispatch(createSetActiveCountdownAction(true))
  }

export const startActiveCountdownAction: ActionCreator<AsyncAction<UpdateMode>> =
  () => (dispatch: Dispatch) => {
    dispatch(createSetActiveCountdownAction(true))
  }

export const createRefreshDataAction: ActionCreator<RefreshData> = () => ({
  type: REFRESH_DATA,
  request: {
    method: 'PUT',
    url: 'upload-driver-sort',
  },
})

export const doRefreshData: ActionCreator<AsyncAction<RefreshData>> =
  () => (dispatch: Dispatch) => {
    dispatch(createSetActiveCountdownAction(true))
    dispatch(createRefreshDataAction())
  }

export const doUpdateApp: ActionCreator<AsyncAction> =
  () => async () => {
    await unregister()
    window.location.reload()
  }

let tickerHandle: any
export const doStartModeTicker: ActionCreator<AsyncAction<LoadMode>> =
  () => (dispatch: Dispatch, getState: GetState) => {
    if (tickerHandle) return

    const tickerFunction = () => {
      const secondsDiff = fromOverview.getTimestampDifference(getState())

      if (secondsDiff >= countdownTime - 2) {
        dispatch(createLoadAction())
      }
    }

    tickerHandle = setInterval(tickerFunction, 30000)
  }
