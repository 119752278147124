import clsx from 'clsx'
import get from 'lodash/get'
import React, { memo } from 'react'

import styles from './styles.module.scss'

enum BUTTON_TYPES {
  PRIMARY = 'primary',
  PRIMARY_REVERSE = 'primary-reverse',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  DEFAULT = 'default',
  DEFAULT_WARNING = 'default-warning',
  LINK = 'link',
  LINK_DANGER = 'link-danger',
  LINK_SUCCESS = 'link-success',
}

const Button = ({
  children,
  className,
  variant = BUTTON_TYPES.DEFAULT,
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { variant?: BUTTON_TYPES | string }) => {
  return (
    <button
      {...props}
      className={clsx(styles.button, get(styles, variant), className)}
    >
      {children}
    </button>
  )
}

export default memo(Button)
