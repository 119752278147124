import { Action } from 'redux'
import { createActionName } from '../../../utils/redux/createActionName'
import { FAILED, LOAD, SUCCESS } from '../../../utils/redux/defaultActionNames'
import { ApiAction, ApiFailureAction, ApiResponseAction } from '../../middleware/handleApiRequests/actions'

const actionNamespace = 'OVERVIEW'

export const SET_COUNTDOWN = createActionName(actionNamespace, 'SET_COUNTDOWN')

export const REFRESH_DATA = createActionName(actionNamespace, 'REFRESH_DATA')
export const REFRESH_DATA_LOAD = REFRESH_DATA + LOAD
export const REFRESH_DATA_SUCCESS = REFRESH_DATA + SUCCESS
export const REFRESH_DATA_FAILED = REFRESH_DATA + FAILED

export interface SetCountdown extends Action<typeof SET_COUNTDOWN> {
  enabled: boolean
}

export interface RefreshData extends ApiAction<typeof REFRESH_DATA> {}
export interface RefreshDataSuccess extends ApiResponseAction<typeof REFRESH_DATA_SUCCESS> {}
export interface RefreshDataFailed extends ApiFailureAction<typeof REFRESH_DATA_FAILED> {}

export type Actions =
  SetCountdown |
  RefreshData |
  RefreshDataSuccess |
  RefreshDataFailed
