import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 312,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
  },

  logo: {
    width: 138,
    height: 16,
    margin: theme.spacing(6),
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  updateButton: {
    fontSize: 12,
    height: 39,
  },

  dateLabel: {
    fontFamily: 'ClanPro-Wide, san-serif',
    color: '#FFF',
    position: 'absolute',
    top: 70,
    left: 40,
  },
})

export default makeStyles(styles)
