import moment from 'moment'
import get from 'lodash/get'
import { createSelector } from 'reselect'

import { State } from './reducer'

export const selectIsAuthenticated = (state: State) => state.isAuthenticated
export const selectGoogleCredentials = (state: State) => state.googleCredentials
export const selectAwsCredentials = (state: State) => state.awsCredentials

export const selectIsGoogleExpired = createSelector(
  selectGoogleCredentials,
  credentials => moment().isAfter(get(credentials, 'expires'))
)
export const selectIsAwsExpired = createSelector(
  selectAwsCredentials,
  credentials => moment().isAfter(get(credentials, 'expires'))
)

export const selectGoogleEmailAddress = createSelector(
  selectGoogleCredentials,
  credentials => get(credentials, 'email'),
)
