import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'

import NavDrawer from '../../../components/Base/NavDrawer'
import ModeSwitchDrawer from '../../../components/Overview/ModeSwitchDrawer'
import ModeSwitcher from '../../../components/Overview/ModeSwitcher'
import NavBar from '../../../components/Base/NavBar'

import { ChildrenProp } from '../../../interfaces/props'
import { PlainEventHandler } from '../../../interfaces/types'
import { SortMode } from '../../../utils/constants/enum'

import useStyles from './useStyles'
import connect from './connect'

export type LayoutWrapperProps = ChildrenProp & {
  mode: SortMode
  onSwitchMode: (mode: SortMode) => void
  logOut: PlainEventHandler
  updateApp: PlainEventHandler
}

export const LayoutWrapper = ({
  children, mode, onSwitchMode, logOut, updateApp,
}: LayoutWrapperProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Hidden mdUp={true}>
        <NavBar onLogOff={logOut} onUpdate={updateApp} />
      </Hidden>

      <Hidden smDown={true}>
        <NavDrawer onLogOff={logOut} onUpdate={updateApp}>
          {mode !== SortMode.unknown && (
            <>
              <Typography className={classes.title} variant='h6' color='textSecondary'>
                {t('main:choose_sort_mode')}
              </Typography>

              <ModeSwitcher mode={mode} onSwitchMode={onSwitchMode} />
            </>
           )}
        </NavDrawer>
      </Hidden>

      <div className={classes.content}>
        {children}
      </div>

      <Hidden mdUp={true}>
        <ModeSwitchDrawer mode={mode} onSwitchMode={onSwitchMode} />
      </Hidden>
    </>
  )
}

LayoutWrapper.propTypes = {
  logOut: PropTypes.func.isRequired,
  updateApp: PropTypes.func.isRequired,
  children: PropTypes.node,
  mode: PropTypes.oneOf(Object.values(SortMode)),
  onSwitchMode: PropTypes.func,
} as any

LayoutWrapper.defaultProps = {
  mode: SortMode.region,
  children: null,
  onSwitchMode: () => null,
} as any

export default connect(LayoutWrapper)
