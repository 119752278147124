import React from 'react'
import { Box } from '@material-ui/core'
import clsx from 'clsx'
import get from 'lodash/get'
import drop from 'lodash/drop'

import Icon from '../Icon'

import styles from './styles.module.scss'

export interface CalloutProps extends React.HTMLAttributes<any> {
  variant?: 'default' | 'primary' | 'success' | 'danger' | 'warning' | string
  icon?: 'default' | 'primary' | 'success' | 'danger' | 'warning' | string
}

const Callout: React.FC<CalloutProps> = ({
  children,
  className,
  variant = 'default',
  icon,
}) => {
  const currentIcon = React.useMemo(() => {
    switch (variant) {
      case 'primary':
        return <Icon name='info-circle' />
      case 'success':
        return <Icon name='check-circle' />
      case 'danger':
        return <Icon name='danger' />
      case 'warning':
        return <Icon name='warning' />
      default:
        return null
    }
  },                                [variant])

  const selectedIcon = React.useMemo(() => {
    switch (icon) {
      case 'primary':
        return <Icon name='info-circle'/>
      case 'success':
        return <Icon name='check-circle' />
      case 'danger':
        return <Icon name='danger'/>
      case 'warning':
        return <Icon name='warning'/>
      default:
        return null
    }
  },                                 [icon])

  const contents = React.Children.toArray(children)

  return (
    <div className={clsx(styles.callout, get(styles, variant), className)}>
      <div>{selectedIcon ? selectedIcon : currentIcon}</div>
      <div>
        {contents.length > 1 ? (
          <>
            <div className={styles.title}>{get(contents, '[0]')}</div>
            {drop(contents)}
          </>
        ) : (
          get(contents, '[0]')
        )}
      </div>
    </div>
  )
}

export default React.memo(Callout)
