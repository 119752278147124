import React, { MouseEvent } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'

import { OnClickProp } from '../../../interfaces/props'

import Google from '../../icons/Google'

import useStyles from './useStyles'

export type GoogleButtonProps = OnClickProp<MouseEvent> & {
  disabled: boolean
}

const GoogleButton = ({
  onClick, disabled,
}: GoogleButtonProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Button
      variant='contained'
      size='large'
      color='default'
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
    >
      <Google className={classes.googleIcon} />

      {t('main:sign_in_with_google')}
    </Button>
  )
}

GoogleButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

GoogleButton.defaultProps = {
  disabled: false,
  onClick: () => null,
}

export default GoogleButton
