import { createReducer } from '../../../utils/redux/createReducer'
import { ApiAction } from '../../middleware/handleApiRequests/actions'

import * as authActions from '../authentication/actionNames'
import * as queueActions from './actionNames'

export interface State {
  readonly deferredActions: ApiAction[]
}

const initialState: State = {
  deferredActions: [],
}

const reducerBlocks = {
  [queueActions.QUEUE_ADD] (state: State, action: queueActions.Add): State {
    return {
      ...state,
      deferredActions: [
        ...state.deferredActions,
        action.deferred,
      ],
    }
  },

  [queueActions.QUEUE_FLUSH] (state: State): State {
    return { ...state, deferredActions: [] }
  },

  [authActions.LOGOUT] () {
    return initialState
  },
}

export const reducer = createReducer<State, queueActions.Actions>(reducerBlocks, initialState)
