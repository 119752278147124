import { Action } from 'redux'
import { createActionName } from '../../../utils/redux/createActionName'
import { ApiAction } from '../../middleware/handleApiRequests/actions'

const actionNamespace = 'REQUEST_QUEUE'

export const QUEUE_ADD = createActionName(actionNamespace, 'ADD')
export const QUEUE_FLUSH = createActionName(actionNamespace, 'FLUSH')

export interface Add extends Action<typeof QUEUE_ADD> {
  deferred: ApiAction
}

export interface Flush extends Action<typeof QUEUE_FLUSH> {}

export type Actions = Add
  & Flush
