import { AxiosRequestConfig } from 'axios'
import { Store } from 'redux'
import get from 'lodash/get'

import { basePathModifier } from '../../../utils/constants'
import { FAILED, LOAD, SUCCESS } from '../../../utils/redux/defaultActionNames'

import * as fromAuth from '../../core/authentication'
import { createAddAction } from '../../core/requestQueue/actions'

import { ApiAction, ApiFailureAction, ApiResponseAction } from './actions'
import client from './client'

const prepareRequest = (request: AxiosRequestConfig, store: Store): AxiosRequestConfig => {
  const signatureClient = fromAuth.getSignatureClient(store.getState())
  const requestConfig = {
    method: request.method,
    headers: {
      ...client.defaults.headers.common,
      ...request.headers,
    },
    path: `/${basePathModifier}/${request.url}`,
    queryParams: request.params,
    body: request.data,
  }
  const {
    headers,
    url,
  } = signatureClient.signRequest(requestConfig)

  return {
    ...request,
    headers,
    url,
  }
}

const makeLoadActionName = (originalName: string) =>
  `${originalName}${LOAD}`

const makeSuccessActionName = (originalName: string): string =>
  `${originalName}${SUCCESS}`

const makeFailureActionName = (originalName: string): string =>
  `${originalName}${FAILED}`

const handleRequest = async (
  store: Store,
  action: ApiAction,
  request: AxiosRequestConfig,
): Promise<void> => {
  const { type, payload } = action

  try {
    const { data } = await client.request(request)
    store.dispatch({
      type: makeSuccessActionName(type),
      payload: {
        result: data,
        originalPayload: payload,
      },
    } as ApiResponseAction)
  } catch (error) {
    const status = get(error, 'response.status')
    if (status === 403 || status === 401) {
      addRequestToQueue(store, action)
      return
    }

    store.dispatch({
      type: makeFailureActionName(type),
      payload: {
        error,
        originalPayload: payload,
      },
    } as ApiFailureAction)
  }
}

const addRequestToQueue = (store: Store, action: ApiAction) =>
  store.dispatch(createAddAction(action))

export const handleApiRequests = (store: Store) => (next: any) => (action: ApiAction) => {
  if (!action.request) {
    next(action)
    return
  }

  const isAwsTokenExpired = fromAuth.isAwsTokenExpired(store.getState())
  if (isAwsTokenExpired) {
    addRequestToQueue(store, action)
    return
  }

  const { type, payload, request } = action

  store.dispatch({ payload, type: makeLoadActionName(type) })

  const axiosConfig = prepareRequest(request, store)
  return handleRequest(store, action, axiosConfig)
    .catch(console.error)
}
