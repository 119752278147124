import { connect } from 'react-redux'
import { AppState } from '../../../store/reducer'

import * as fromSortMode from '../../../store/entities/sortMode'

import * as sortModeActions from '../../../store/entities/sortMode/actions'
import * as chuteActions from '../../../store/entities/driverChutes/actions'
import * as overviewActions from '../../../store/features/overview/actions'

const mapStateToProps = (state: AppState) => ({
  hasFetchedMode: fromSortMode.hasFetched(state),
  currentMode: fromSortMode.getMode(state),
})

const mapDispatchToProps = {
  loadMode: sortModeActions.createLoadAction,
  loadChutes: chuteActions.createLoadAction,
  loadCapacitySlots: sortModeActions.createLoadCapacityListAction,
  startModeTicker: overviewActions.doStartModeTicker,
}

export default connect(mapStateToProps, mapDispatchToProps)
