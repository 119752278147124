import { createReducer } from '../../../utils/redux/createReducer'

import { DriverChute } from '../../../interfaces/models/driverChute'
import { LOGOUT } from '../../core/authentication/actionNames'

import * as actions from './actionNames'
import normalize from './schema'

export interface State {
  readonly isLoading: boolean
  readonly hasFetched: boolean
  readonly identifiers: number[]
  readonly data: { [id: number]: DriverChute }
  readonly lastError: Error | null
}

const initialState: State = {
  isLoading: false,
  hasFetched: false,
  identifiers: [],
  data: {},
  lastError: null,
}

const reducerBlocks = {
  [actions.CHUTES_LOAD](state: State) {
    return { ...state, isLoading: true, lastError: null }
  },

  [actions.CHUTES_SUCCESS] (state: State, { payload } : actions.LoadSuccess) {
    const [identifiers, data] = normalize(payload.result.drivers)

    return {
      ...state,
      identifiers,
      data,
      isLoading: false,
    }
  },

  [actions.CHUTES_FAILED](state: State, action: actions.LoadFailed) {
    const { payload: { error: lastError } } = action

    return { ...state, lastError, isLoading: false }
  },

  [LOGOUT]() {
    return { ...initialState }
  },
}

export const reducer = createReducer<State, actions.Actions>(reducerBlocks, initialState)
