import React, { memo } from 'react'

const DropdownMenuItem = memo(({ children, className, ...props }: any) => {
  return (
    <li className={className} {...props}>
      {children}
    </li>
  )
})

DropdownMenuItem.displayName = 'DropdownMenuItem'

export default DropdownMenuItem
