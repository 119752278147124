import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { SortMode } from '../../../../utils/constants/enum'
import RadioButton from '../../../Inputs/RadioButton'

import useStyles from './useStyles'

export type SwitchItemProps = {
  mode: SortMode
  selected: boolean
  onSelect: (mode: SortMode) => void
}

const SwitchItem = ({
  mode, selected, onSelect,
}: SwitchItemProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSelection = () =>
    !selected && onSelect(mode)

  return (
    <Button
      variant='contained'
      onClick={handleSelection}
      className={clsx(classes.item, get(classes, mode))}
    >
      <RadioButton
        selected={selected}
        className={classes.radioButton}
      />

      <Typography className={classes.text} variant='h5' color='textSecondary'>
        {
          mode === SortMode.region
            ? t('main:region_mode')
            : mode === SortMode.missedConnection ? t('main:missed_connection_mode') : t('main:center_mode')
        }
      </Typography>
    </Button>
  )
}

SwitchItem.propTypes = {
  mode: PropTypes.oneOf(Object.values(SortMode)),
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

SwitchItem.defaultProps = {
  mode: SortMode.region,
  selected: false,
  onSelect: () => null,
}

export default SwitchItem
