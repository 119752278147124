import { AnyAction, Reducer } from 'redux'

export type BlockReducer<TState, TActions extends AnyAction> = { [actionName: string ]: SingleReducerBlock<TState, TActions> }
export type SingleReducerBlock<TState, TActions> = (state: TState, action: TActions | any) => TState

export const createReducer = <TState, TActions extends AnyAction>(
  reducer: BlockReducer<TState, TActions>,
  initialState: TState,
): Reducer<TState, TActions> =>
  (state = initialState, action: TActions) => {
    const { type } = action

    if (reducer[type]) {
      return reducer[type](state, action)
    }

    return state
  }
