import { Action } from 'redux'
import { createActionName } from '../../../utils/redux/createActionName'

const actionNamespace = 'AUTHENTICATION'

export const UPDATE_TOKEN = createActionName(actionNamespace, 'UPDATE_TOKEN')
export const UPDATE_AWS_CREDS = createActionName(actionNamespace, 'UPDATE_AWS_CREDS')
export const LOGOUT = createActionName(actionNamespace, 'LOGOUT')

export interface UpdateToken extends Action<typeof UPDATE_TOKEN>{
  payload: { token: string, expires: Date, email: string }
}

export interface UpdateAwsKeys extends Action<typeof UPDATE_AWS_CREDS>{
  payload: {
    accessKeyId: string
    secretAccessKey: string
    sessionToken: string
    expires: Date
  }
}

export interface Logout extends Action<typeof LOGOUT> {}

export type Actions =
  UpdateToken |
  UpdateAwsKeys |
  Logout
