import { connect } from 'react-redux'

import * as authActions from '../../../store/core/authentication/actions'
import * as overviewActions from '../../../store/features/overview/actions'

const mapDispatchToProps = {
  updateApp: overviewActions.doUpdateApp,
  logOut: authActions.createLogoutAction,
}

export default connect(null, mapDispatchToProps)
