import React, { ReactNode, KeyboardEvent, useEffect } from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

import { PlainEventHandler } from '../../../interfaces/types'
import { SortMode } from '../../../utils/constants/enum'
import { useChangeEvent } from '../../../utils/hooks'

import Dialog from '../../Base/Dialog'
import Field from '../../Inputs/Field'
import ConfirmationActions, { ButtonProps } from './ConfirmationActions'

import useStyles from './useStyles'
import { Icon } from '../../Base/.raw';

export type ConfirmationDialogProps = {
  open: boolean
  isLoading?: boolean
  isPassword: boolean
  color?: SortMode | 'default'
  title?: ReactNode | string
  message?: ReactNode | string
  label?: ReactNode | string
  confirmText?: string,
  confirmButtonLabel?: ReactNode | string
  classN?: string
  errorMessage?: null | string,
  showErrorMessage?: null | boolean
  confirmButtonProps?: ButtonProps
  closeButtonProps?: ButtonProps
  onConfirm?: (password: string, ...args:any) => void
  onClose?: PlainEventHandler
}

const ConfirmationDialog = ({
  color, isLoading, isPassword, open, title, confirmText, message, label, confirmButtonLabel,
  classN, confirmButtonProps, closeButtonProps, errorMessage, showErrorMessage, onConfirm, onClose,
}: ConfirmationDialogProps) => {
  const classes = useStyles()
  const [value, handleChange, setValue] = useChangeEvent()
  const isValid = (isPassword && value !== '')
    || (!isPassword && confirmText === value)

  useEffect(
    () => setValue(''),
    [open, setValue]
  )

  const handleOnConfirm = () => {
    onConfirm && onConfirm(value as string)
    setValue('')
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    const canInvokeConfirm = event.keyCode === 13 && isValid

    if (canInvokeConfirm) {
      handleOnConfirm()
    }
  }

  return (
    <Dialog
      open={open}
      title={title}
      className={classes.dialog}
    >
      <Dialog.Content>
      <Box display='flex' justifyContent='start'>
          <Typography variant='body1' align='left' className={classes.message}>
            {message}
          </Typography>
        </Box>
        <Box marginTop={2}>
          <Typography variant='body1' align='left'>
            {label}
          </Typography>
        </Box>
        <Box marginTop={3}>
          <div>
            {isLoading ? <Icon name='loading' className={classes.loadingIcon}/> : (
              <Field
                type={isPassword ? 'password' : 'text'}
                color={color}
                fullWidth={true}
                autoFocus={true}
                disabled={isLoading}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                value={value}
                className={clsx(classes.password, showErrorMessage && classes.errorInput)}
              />
            )}
          </div>
          {showErrorMessage &&
            <span className={classes.error}>
              {errorMessage}
            </span>
          }
        </Box>
      </Dialog.Content>

      <ConfirmationActions
        disabled={!isValid || isLoading}
        label={confirmButtonLabel}
        onConfirm={handleOnConfirm}
        confirmButtonProps={confirmButtonProps}
        closeButtonProps={closeButtonProps}
        onClose={onClose}
        classN={classN}
      />
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  color: PropTypes.oneOf(['default', ...Object.values(SortMode)]),
  open: PropTypes.bool,
  isPassword: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classN: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}

ConfirmationDialog.defaultProps = {
  color: 'default',
  open: false,
  isPassword: false,
  isLoading: false,
  message: null,
  label: null,
  confirmButtonLabel: null,
  classN: null,
  onConfirm: () => null,
  onClose: () => null,
}

export default ConfirmationDialog
