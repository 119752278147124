import { connect } from 'react-redux'
import { AppState } from '../../store/reducer'

import * as overviewActions from '../../store/features/overview/actions'
import * as sortModeActions from '../../store/entities/sortMode/actions'

import * as fromOverview from '../../store/features/overview'
import * as fromSortMode from '../../store/entities/sortMode'
import * as fromChutes from '../../store/entities/driverChutes'

const mapStateToProps = (state: AppState) => ({
  mode: fromSortMode.getMode(state),
  hasFetchedMode: fromSortMode.hasFetched(state),
  hasModeError: !!fromSortMode.getLastError(state),
  switchCapacityError: fromSortMode.getSwitchCapacityError(state),
  closeCapacityError: fromSortMode.getCloseCapacityError(state),
  countdownActive: fromOverview.hasCountdownActive(state),
  countdownTime: fromOverview.getCountdownSeconds(state),
  chutes: fromChutes.getDriverChutes(state),
  isCompletingSorting: fromSortMode.isCompleting(state),
})

const mapDispatchToProps = {
  loadMode: sortModeActions.createLoadAction,
  updateMode: overviewActions.createUpdateModeAction,
  retryMode: sortModeActions.doRetryFetchingMode,
  refreshData: overviewActions.doRefreshData,
  completeSorting: sortModeActions.doCompleteSortMode,
  initCapacityActions: sortModeActions.initSwitchCapacityAction,
  switchCapacitySlot: sortModeActions.createSwitchCapacitySlotAction,
  closeCapacitySlot: sortModeActions.createCloseCapacitySlotAction,
  startCountdown: overviewActions.startActiveCountdownAction,
}

export default connect(mapStateToProps, mapDispatchToProps)
