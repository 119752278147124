import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'

import { PlainEventHandler } from '../../../interfaces/types'
import { OpenProp } from '../../../interfaces/props'
import { countdownTime } from '../../../utils/constants'
import { SortMode } from '../../../utils/constants/enum'

import ConfirmationDialog from '../ConfirmationDialog'

import useStyles from './useStyles'

export type SwitchModeDialogProps = OpenProp & {
  targetMode: SortMode
  isLoading: boolean
  onSwitchMode: (password: string) => void
  onClose: PlainEventHandler
}

const SwitchModeDialog = ({
    open, isLoading, targetMode, onSwitchMode, onClose,
}: SwitchModeDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
        open={open}
        isLoading={isLoading}
        isPassword={true}
        title={t('main:change_sortmode')}
        color={targetMode}
        confirmText={targetMode}
        message={t('main:switch_mode_dialog_confirmation', { countdownTime })}
        label={(
          <Trans i18nKey='main:switch_mode_dialog_label'>
            <span className={classes.targetMode}>{{ targetMode }}</span>
          </Trans>
        )}
        confirmButtonLabel={t('main:confirm_change')}
        onConfirm={onSwitchMode}
        onClose={onClose}
    />
  )
}

SwitchModeDialog.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  targetMode: PropTypes.oneOf(Object.values(SortMode)),
  onSwitchMode: PropTypes.func,
  onClose: PropTypes.func,
}

SwitchModeDialog.defaultProps = {
  open: false,
  isLoading: false,
  targetMode: SortMode.center,
  onSwitchMode: () => null,
  onClose: () => null,
}

export default SwitchModeDialog
