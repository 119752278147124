import get from 'lodash/get'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { lighten, fade } from '@material-ui/core/styles/colorManipulator'
import { SortMode } from '../../../utils/constants/enum'

const styles = (theme: Theme) => createStyles({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    transition: theme.transitions.create(
      ['background-color'],
      { duration: theme.transitions.duration.standard },
    ),
  },

  [SortMode.unknown]: {
    backgroundColor: lighten(theme.palette.primary.main, 0.05),
  },

  [SortMode.region]: {
    backgroundColor: get(theme, 'sortColors.region'),
  },

  [SortMode.center]: {
    backgroundColor: get(theme, 'sortColors.center'),
  },

  [SortMode.missedConnection]: {
    backgroundColor: get(theme, 'sortColors.missedConnection'),
  },

  sortingTitle: {
    marginBottom: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },

  text: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },

  childContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(7),

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },

  build: {
    position: 'absolute',
    color: fade(theme.palette.common.white, 0.5),

    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },

    [theme.breakpoints.down('sm')]: {
      top: 0,
      left: theme.spacing(1),

      '& > span': {
        fontSize: 10,
      },
    },
  },
  capacitySwitchContainer: {
    position: 'absolute',
    top: 48,
    left: 48,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    '& > div': {
      position: 'relative',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      height: '100%',
      left: 0,
      alignItems: 'center',
    },
  },
  capacitySwitch: {
    width: 'auto',
    height: 48,

    '& > div > div > span:first-of-type' : {
      paddingLeft: 40,
      paddingRight: 20,
      top: 2,
      position: 'relative',
      display: 'flex',
    },
    '& > div > div > svg:last-of-type' : {
      top: 14,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none',
      marginBottom: 100,
      [theme.breakpoints.down('sm')]: {
        width: 360,
      },
    },
  },
  capacitySwitchBottom: {
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  capacitySwitchMenuItem: {
    width: 448,

    '& > div' : {
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      width: 352,
    },
  },
  capacitySwitchIcon: {
    position: 'absolute',
    top: 12,
    left: 16,
    zIndex: 1,
  },
  hidden : {
    display: 'none',
  },
  capacitySlotButtonSelector: {
    display: 'flex',

    '& > div:nth-child(even) > div > button' : {
      marginLeft: 8,
    },

    '& > div > div > button > div' : {
      position: 'relative',
      top: 2,
      left: -4,
    },

    '& > div > div > button > div > svg' : {
      height: 18,
      width: 18,
    },
  },
  currentCapacitySlotText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',

    '& > button' : {
      position: 'relative',
      right: 30,
      top: -2,
      [theme.breakpoints.down('sm')]: {
        width: 40,
        height: 34,
      },
    },
  },
  currentCapacitySlotTextIcon: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  currentCapacitySlotTextLabel: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  closeCapacityContainer: {
    display: 'flex',
    position: 'relative',
    right: 96,
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      right: 0,
      justifyContent: 'center',
    },
  },

  tooltip: {
    backgroundColor: '#220C4A',
  },

  callout: {
    marginTop: 24,

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 48,
    },
  },
})

export default makeStyles(styles)
