import React, { memo } from 'react'

const DropdownMenuAction = memo(({ children, onClick, disabled, ...props }: any) => {
  const handleClick = (...args: any) => {
    if (!disabled) {
      onClick && onClick(...args)
      document.dispatchEvent(new Event('mousedown'))
    }
  }
  return (
    <div onClick={handleClick} disabled={disabled} {...props}>
      {children}
    </div>
  )
})

DropdownMenuAction.displayName = 'DropdownMenuAction'

export default DropdownMenuAction
