import { SortMode } from '../../../utils/constants/enum'
import { createReducer } from '../../../utils/redux/createReducer'
import { LOGOUT } from '../../core/authentication/actionNames'

import { mapResponseToMode } from './helpers'
import * as modeActions from './actionNames'
import { CapacitySlot } from '../../../interfaces/models/capacitySlot';
import get from 'lodash/get'

export interface State {
  readonly isLoading: boolean
  readonly isWorking: boolean
  readonly isCompleting: boolean
  readonly hasFetched: boolean
  readonly mode: SortMode
  readonly lastError: Error | null
  readonly switchCapacityError: string | null,
  readonly capacityActionsActive?: boolean,
  readonly capacityActionsLoading?: boolean,
  readonly capacityActionsSucceeded?: boolean,
  readonly closeCapacityError: string | null,
  readonly capacitySlots: CapacitySlot[]
  readonly networkType?: 'DAY' | 'NIGHT' // required on REGION mode
  readonly capacitySlotDate?: string // YYYY-MM-DD required on REGION mode
  readonly currentCapacitySlotClosed?: boolean
}

export const initialState: State = {
  isLoading: false,
  isWorking: false,
  isCompleting: false,
  hasFetched: false,
  mode: SortMode.unknown,
  lastError: null,
  switchCapacityError: null,
  closeCapacityError: null,
  capacitySlots: [],
}

const reducerBlocks = {
  [modeActions.MODE_LOAD](state: State): State {
    return { ...state, isLoading: true, lastError: null }
  },

  [modeActions.MODE_SUCCESS](state: State, action: modeActions.LoadModeSuccess): State {
    const { payload: { result } } = action
    const mode = mapResponseToMode(result)

    const capacitySlotDate = get(result, 'capacitySlotDate')
    const networkType = get(result, 'networkType')
    const currentCapacitySlotClosed = get(result, 'isClosed')

    return { ...state, mode, capacitySlotDate, networkType, currentCapacitySlotClosed,  isLoading: false, hasFetched: true }
  },

  [modeActions.MODE_FAILED](state: State, action: modeActions.LoadModeFailed): State {
    const { payload: { error: lastError } } = action

    return { ...state, lastError, hasFetched: true, isLoading: false }
  },

  [modeActions.MODE_RETRY](state: State): State {
    return { ...state, hasFetched: false, lastError: null }
  },

  [modeActions.UPDATE_MODE_LOAD](state: State): State {
    return { ...state, isWorking: true, capacityActionsActive: false, lastError: null }
  },

  [modeActions.UPDATE_MODE_SUCCESS](state: State): State {
    return { ...state, isWorking: false }
  },

  [modeActions.UPDATE_MODE_FAILED](state: State, action: modeActions.UpdateModeFailed): State {
    const { payload: { error: lastError }  } = action

    return { ...state, lastError, isWorking: false }
  },

  [modeActions.COMPLETE_SORTING_LOAD](state: State): State {
    return { ...state, isCompleting: true }
  },

  [modeActions.COMPLETE_SORTING_SUCCESS](state: State): State {
    return { ...state, isCompleting: false }
  },

  [modeActions.COMPLETE_SORTING_FAILED](state: State): State {
    return { ...state, isCompleting: false }
  },

  [modeActions.MISSED_CONNECTION_MODE](state: State): State {
    return { ...state, isWorking: true, lastError: null }
  },

  [modeActions.MISSED_CONNECTION_MODE_SUCCESS](state: State): State {
    return { ...state, isWorking: false }
  },

  [modeActions.MISSED_CONNECTION_MODE_FAILED](state: State, action: modeActions.MissedConnectionModeFailed): State {
    const { payload: { error: lastError }  } = action

    return { ...state, lastError, isWorking: false }
  },

  [modeActions.CAPACITY_SLOTS](state: State): State {
    return { ...state, isLoading: true, lastError: null }
  },

  [modeActions.CAPACITY_SLOTS_SUCCESS](state: State, action: modeActions.CapacitySlotsSuccess): State {
    const capacitySlots = get(action, 'payload.result.capacitySlots', [])
    return { ...state, capacitySlots, isLoading: false }
  },

  [modeActions.CAPACITY_SLOTS_FAILED](state: State, action: modeActions.CapacitySlotsFailed): State {
    const { payload: { error: lastError } } = action

    return { ...state, lastError, isLoading: false }
  },

  [modeActions.INIT_CAPACITY_SLOT_ACTIONS](state: State): State {
    return { ...state, switchCapacityError: null, capacityActionsActive: true, capacityActionsLoading: false, capacityActionsSucceeded: false }
  },

  [modeActions.SWITCH_CLOSE_CAPACITY_SLOT](state: State, action: modeActions.CloseCapacitySlot): State {
    // const currentCapacitySlotDate = get(action, 'payload.capacitySlot.capacitySlotDate')
    // const currentCapacitySlotNetwork = get(action, 'payload.capacitySlot.networkType')
    //
    // const nextCapacitySlots = state.capacitySlots.map((capacitySlot: CapacitySlot) => {
    //   const { networkType, date } = capacitySlot
    //   if (currentCapacitySlotDate === moment(date).format('YYYY-MM-DD') && currentCapacitySlotNetwork === networkType) {
    //     return {
    //       ...capacitySlot,
    //       isClosed: true,
    //     }
    //   }
    //   return capacitySlot
    // })

    return { ...state, closeCapacityError: null, switchCapacityError: null, capacityActionsActive: true, capacityActionsLoading: true, capacityActionsSucceeded: false }
  },

  [modeActions.SWITCH_CLOSE_CAPACITY_SLOT_FAILED](state: State, action: modeActions.SwitchCapacitySlotFailed): State {
    const closeCapacityError = get(action, 'payload.error.response.data.message')
    return { ...state, closeCapacityError, capacityActionsLoading: false }
  },

  [modeActions.SWITCH_CLOSE_CAPACITY_SLOT_SUCCESS](state: State, action: modeActions.SwitchCapacitySlotSuccess): State {
    const capacitySlotDate = get(action, 'payload.result.capacitySlotDate')
    const networkType = get(action, 'payload.result.networkType')

    return { ...state, capacitySlotDate, networkType, capacityActionsLoading: false, capacityActionsSucceeded: true }
  },

  [modeActions.SWITCH_CAPACITY_SLOT_LOAD](state: State, action: modeActions.SwitchCapacitySlotLoad): State {
    return { ...state, switchCapacityError: null, capacityActionsActive: true, capacityActionsLoading: true, capacityActionsSucceeded: false  }
  },

  [modeActions.SWITCH_CAPACITY_SLOT_SUCCESS](state: State, action: modeActions.SwitchCapacitySlotSuccess): State {
    const capacitySlotDate = get(action, 'payload.result.capacitySlotDate')
    const networkType = get(action, 'payload.result.networkType')

    return { ...state, capacitySlotDate, networkType, switchCapacityError: null, capacityActionsLoading: false, capacityActionsSucceeded: true }
  },

  [modeActions.SWITCH_CAPACITY_SLOT_FAILED](state: State, action: modeActions.SwitchCapacitySlotFailed): State {
    const switchCapacityError = get(action, 'payload.error.response.data.message')
    return { ...state, switchCapacityError, capacityActionsLoading: false }
  },

  [modeActions.CLOSE_CAPACITY_SLOT_LOAD](state: State, action: modeActions.SwitchCapacitySlotFailed): State {
    return { ...state, closeCapacityError: null, capacityActionsActive: true, capacityActionsLoading: true  }
  },

  [modeActions.CLOSE_CAPACITY_SLOT_SUCCESS](state: State, action: modeActions.CloseCapacitySlotSuccess): State {
    return {
      ...state,
      closeCapacityError: null, capacityActionsLoading: false }
  },

  [modeActions.CLOSE_CAPACITY_SLOT_FAILED](state: State, action: modeActions.CloseCapacitySlotFailed): State {
    const closeCapacityError = get(action, 'payload.error.response.data.message')
    return { ...state, closeCapacityError, capacityActionsLoading: false, capacityActionsSucceeded: false }
  },

  [LOGOUT] () {
    return initialState
  },
}

export const reducer = createReducer<State, modeActions.Actions>(reducerBlocks, initialState)
