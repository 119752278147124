import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Paper from '@material-ui/core/Paper'

import { ChildrenProp, ClassNameProp } from '../../../interfaces/props'

import useStyles from './useStyles'

export type LoginPaperProps = ChildrenProp & ClassNameProp

const LoginPaper = ({
    className, children,
}: LoginPaperProps) => {
  const classes = useStyles()

  return (
        <div className={classes.gradientContainer}>
            <div className={classes.gradientHost}>
                <div className={classes.gradientUpper} />
                <div className={classes.gradientLower} />
            </div>

            <Paper className={clsx(classes.paper, className)}>
                {children}
            </Paper>
        </div>
  )
}

LoginPaper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

LoginPaper.defaultProps = {
  className: null,
  children: null,
}

export default LoginPaper
