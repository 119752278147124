import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import LandscapeModal from '../components/Base/LandscapeModal'

import RouteLogin from './RouteLogin'
import RouteOverview from './RouteOverview'

import connect from './connect'

export type AppProps = { isAuthenticated: boolean }

export const RedirectToLogin = () =>
  <Redirect to='/login' />

export const RedirectToOverview = () =>
  <Redirect to='/overview' />

export const App = ({
  isAuthenticated,
}: AppProps) => (
  <LandscapeModal>
    <Switch>
      {!isAuthenticated && <Route path='/login' component={RouteLogin} />}
      {isAuthenticated && <Route path='/overview' component={RouteOverview} />}

      <Route component={isAuthenticated ? RedirectToOverview : RedirectToLogin} />
    </Switch>
  </LandscapeModal>
)

export default connect(App)
