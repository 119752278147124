/* tslint:disable:max-line-length */
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

const TrunkrsIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox='0 0 460 285'>
        <path fill='#00d163' d='M10.53,90.17a7.41,7.41,0,0,0,5,12.89l133.6-.37a11.82,11.82,0,0,0,7.9-3.06l99.5-90.44L109.34,9.6A26.47,26.47,0,0,0,91.6,16.48Z'/>
        <path fill='#201047' d='M444.52,9.24l-160.77.38a13.59,13.59,0,0,0-9.07,3.51l-95.3,86.26a11.28,11.28,0,0,0-3.69,8.35v66.6a7.05,7.05,0,0,1-7,7l-61.48.14A25.62,25.62,0,0,0,90,188.18L8.69,262.11a7.41,7.41,0,0,0,5,12.89L172,274.62a9.66,9.66,0,0,0,6.48-2.5l96.4-87.26a11.29,11.29,0,0,0,3.69-8.35v-66.6a7.05,7.05,0,0,1,7-7l65.94-.16a14,14,0,0,0,9.38-3.64l87.58-79.6A5.87,5.87,0,0,0,444.52,9.24Z'/>
    </SvgIcon>
)

TrunkrsIcon.propTypes = SvgIcon.propTypes
TrunkrsIcon.defaultProps = SvgIcon.defaultProps

export default TrunkrsIcon
