export const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

export const baseApiUrl = process.env.REACT_APP_BASE_URL
  || 'https://41bqym51vk.execute-api.eu-west-1.amazonaws.com'

export const basePathModifier = process.env.REACT_APP_BASE_PATH
  || 'dev'

export const countdownTime = process.env.REACT_APP_COUNTDOWN ||
  isDevelopment ? 60 : 120

export const buildNumber = process.env.REACT_APP_BUILD_NUMBER ||
  'development'
