import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import moment from 'moment'

import ChuteDialog from '../../components/Dialogs/ChuteDialog'
import CompleteSortingDialog from '../../components/Dialogs/CompleteSortingDialog'
import CountdownDialog from '../../components/Dialogs/CountdownDialog'
import RefreshDataDialog from '../../components/Dialogs/RefreshDataDialog'
import SwitchModeDialog from '../../components/Dialogs/SwitchModeDialog'
import { DriverChute } from '../../interfaces/models/driverChute'

import { PlainEventHandler } from '../../interfaces/types'
import { SortMode } from '../../utils/constants/enum'

import connect from './connect'
import EntityLoader from './EntityLoader'

import LayoutWrapper from './LayoutWrapper'
import ModeView from './ModeView'
import SwitchCapacitySlotDialog from '../../components/Dialogs/SwitchCapacitySlotDialog';
import CloseCapacitySlotDialog from '../../components/Dialogs/CloseCapacitySlotDialog';

import { capitalize } from '../../utils/strings'
import { getIsCapacityActionsActive, getIsCapacityActionsSucceeded } from '../../store/entities/sortMode';
import { useSelector } from 'react-redux';
import { mapServerText } from '../../utils/strings/mapServerText';

export type RouteOverviewProps = {
  mode: SortMode
  chutes: DriverChute[]
  hasFetchedMode: boolean
  hasModeError: boolean
  countdownActive: boolean
  countdownTime: number
  isCompletingSorting: boolean
  loadMode: PlainEventHandler
  updateMode: (options: { mode: SortMode, password: string }) => void
  completeSorting: (options: { password:string }) => void
  retryMode: PlainEventHandler
  refreshData: PlainEventHandler
  startCountdown: PlainEventHandler
  switchCapacitySlot: (options: {capacitySlotDate?: string, networkType?: string, password: string}) => void
  closeCapacitySlot: (options: {password: string, capacitySlotDate?: string, networkType?: string}) => void
  switchCapacityError?: null|string
  closeCapacityError?: null|string
  initCapacityActions: PlainEventHandler
}

export const RouteOverview = ({
  mode, chutes, hasFetchedMode, hasModeError, switchCapacityError, closeCapacityError, initCapacityActions,
  isCompletingSorting, countdownActive, countdownTime,
  loadMode, updateMode, retryMode, completeSorting, refreshData, switchCapacitySlot, closeCapacitySlot,
}: RouteOverviewProps) => {
  const [targetMode, setTargetMode] = useState<SortMode|null>()
  const [refreshOpen, setRefreshOpen] = useState(false)
  const [chuteListOpen, setChuteListOpen] = useState(false)
  const [selectedCapacitySlot, setSelectedCapacitySlot] = useState({
    networkType: undefined, capacitySlotDate: undefined,
  })
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [isSwitchDialogOpen, setIsSwitchDialogOpen] = useState(false)
  const [showCloseCapErrMsg, setShowCloseCapErrMsg] = useState(false)

  const [isCloseCapacityDialogOpen, setIsCloseCapacityDialogOpen] = useState(false)
  const [completeSortingOpen, setCompleteSortingOpen] = useState(false)
  const [isCompleteSortingLoading, setCompleteSortingLoading] = useState(false)

  const isCapacityActionsActive = useSelector(getIsCapacityActionsActive)
  const isCapacityActionsSucceeded = useSelector(getIsCapacityActionsSucceeded)

  useEffect(
    () => {
      if (!switchCapacityError && showErrorMessage) {
        setShowErrorMessage(false)
        setSelectedCapacitySlot({
          networkType: undefined, capacitySlotDate: undefined,
        })
      }

      if (switchCapacityError && isSwitchDialogOpen) {
        setShowErrorMessage(true)
      }
    },
    [showErrorMessage, switchCapacityError, selectedCapacitySlot, isSwitchDialogOpen, setShowErrorMessage],
  )

  useEffect(() => {
    if (isCapacityActionsSucceeded) {
      setSelectedCapacitySlot({
        networkType: undefined, capacitySlotDate: undefined,
      })
      setIsCloseCapacityDialogOpen(false)
      setShowCloseCapErrMsg(false)
    }
  },        [isCapacityActionsSucceeded])

  useEffect(() => {
    if (closeCapacityError) {
      setShowCloseCapErrMsg(true)
      setIsCloseCapacityDialogOpen(true)
    }
  },        [closeCapacityError, closeCapacitySlot])

  useEffect(
    () => {
      if (isCompleteSortingLoading && !isCompletingSorting) {
        setCompleteSortingLoading(false)
        setCompleteSortingOpen(false)
      }
    },
    [isCompleteSortingLoading, isCompletingSorting],
  )

  const handleStartSwitchMode = (mode: SortMode) =>
    setTargetMode(mode)
  const handleContinueSwitchMode = (password: string) => {
    updateMode({ password, mode: targetMode as SortMode })
    setTargetMode(null)
  }
  const handleContinueCompleteSorting = (password: string) => {
    completeSorting({ password })
    setTimeout(() => setCompleteSortingLoading(true))
  }
  const handleCloseSwitchMode = () =>
    setTargetMode(null)

  const handleStartRefresh = () =>
    setRefreshOpen(true)
  const handleContinueRefresh = () => {
    refreshData()
    setRefreshOpen(false)
  }
  const handleCloseRefresh = () =>
    setRefreshOpen(false)

  const handleToggleChuteList = () =>
    setChuteListOpen(!chuteListOpen)

  const handleToggleCompleteSorting = () =>
    setCompleteSortingOpen(!completeSortingOpen)

  const handleCapacitySlotValue = (capacitySlotDate: string, networkType: string) => {
    initCapacityActions()
    setSelectedCapacitySlot({ networkType, capacitySlotDate } as any)
  }

  const handleSwitchCapacitySlot = (password: string) => {
    if (switchCapacityError) {
      setShowErrorMessage(true)
    }
    setIsSwitchDialogOpen(true)

    switchCapacitySlot({
      ...selectedCapacitySlot,
      password,
    })
  }

  const handleCloseSwitchCapacitySlotAction = () => {
    setSelectedCapacitySlot({
      networkType: undefined, capacitySlotDate: undefined,
    })
    setShowErrorMessage(false)
    setIsSwitchDialogOpen(false)
  }

  const openCloseCapacitySlotDialog = () => {
    initCapacityActions()
    setIsCloseCapacityDialogOpen(true)
  }

  const closeCloseCapacitySlotDialog = () => {
    setSelectedCapacitySlot({
      networkType: undefined, capacitySlotDate: undefined,
    })
    setIsCloseCapacityDialogOpen(false)
    setShowCloseCapErrMsg(false)
  }

  const handleCloseCapacitySlot = (password: string) => {
    if (closeCapacityError) {
      setShowCloseCapErrMsg(true)
    }
    // if (!closeCapacityError && !showCloseCapErrMsg) {
    //   setIsCloseCapacityDialogOpen(false)
    //   setShowCloseCapErrMsg(false)
    // }
    closeCapacitySlot({
      password,
      ...selectedCapacitySlot,
    })
    // setSelectedCapacitySlot({
    //   networkType: undefined, capacitySlotDate: undefined,
    // })
  }

  return (
    <LayoutWrapper mode={mode} onSwitchMode={handleStartSwitchMode}>
      <ModeView
        mode={mode}
        hasFetchedMode={hasFetchedMode}
        hasError={hasModeError}
        switchCapacityError={switchCapacityError}
        onCompleteSorting={handleToggleCompleteSorting}
        onStartRefresh={handleStartRefresh}
        onShowChuteList={handleToggleChuteList}
        onCapacitySlotSwitch={handleCapacitySlotValue}
        onCloseCapacitySlot={openCloseCapacitySlotDialog}
        onRetry={retryMode}
        selectedCapacitySlot={selectedCapacitySlot}
      />

      <EntityLoader
        canLoadChutes={mode === SortMode.center && chuteListOpen}
      />

      <SwitchCapacitySlotDialog
        open={!!selectedCapacitySlot.capacitySlotDate && !isCapacityActionsSucceeded && !isCloseCapacityDialogOpen}
        capacitySlotDateText={`${moment(selectedCapacitySlot.capacitySlotDate).format('MMMM DD')} - ${capitalize(mapServerText(selectedCapacitySlot.networkType))}`}
        onSubmit={handleSwitchCapacitySlot}
        onClose={handleCloseSwitchCapacitySlotAction}
        switchCapacityError={switchCapacityError}
        showErrorMessage={showErrorMessage}
      />

      <CloseCapacitySlotDialog
        open={isCloseCapacityDialogOpen}
        onSubmit={handleCloseCapacitySlot}
        onCapacitySlotSwitch={handleCapacitySlotValue}
        selectedCapacitySlot={selectedCapacitySlot}
        onClose={closeCloseCapacitySlotDialog}
        closeCapacityError={closeCapacityError}
        showErrorMessage={showCloseCapErrMsg}
      />

      <SwitchModeDialog
        open={!!targetMode}
        targetMode={targetMode as SortMode}
        onSwitchMode={handleContinueSwitchMode}
        onClose={handleCloseSwitchMode}
      />

      <RefreshDataDialog
        open={refreshOpen}
        onRefresh={handleContinueRefresh}
        onClose={handleCloseRefresh}
      />

      <ChuteDialog
        open={chuteListOpen}
        chutes={chutes}
        onClose={handleToggleChuteList}
      />

      <CompleteSortingDialog
        open={completeSortingOpen}
        isLoading={isCompletingSorting}
        onCompleteSorting={handleContinueCompleteSorting}
        onClose={handleToggleCompleteSorting}
      />

      {countdownActive && !isCapacityActionsActive && (
        <CountdownDialog
          seconds={countdownTime}
          onCountdownDone={loadMode}
        />
      )}
    </LayoutWrapper>
  )
}

RouteOverview.propTypes = {
  loadMode: PropTypes.func.isRequired,
  updateMode: PropTypes.func.isRequired,
  retryMode: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(SortMode)),
  chutes: PropTypes.array,
  hasFetchedMode: PropTypes.bool,
  hasModeError: PropTypes.bool,
  switchCapacityError: PropTypes.string,
  closeCapacityError: PropTypes.string,
  countdownActive: PropTypes.bool,
  startCountdown: PropTypes.func,
  initCapacityActions: PropTypes.func,
} as any

RouteOverview.defaultProps = {
  mode: SortMode.unknown,
  chutes: [],
  hasFetchedMode: false,
  hasModeError: false,
  countdownActive: false,
  switchCapacityError: null,
  closeCapacityError: null,
} as any

export default connect(RouteOverview)
