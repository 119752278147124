import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  menuHost: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.shape.borderRadius,
    },
  },
})

export default makeStyles(styles)
