import { createReducer } from '../../../utils/redux/createReducer'

import * as authActions from './actionNames'

export interface State {
  readonly isAuthenticated: boolean
  readonly googleCredentials?: {
    readonly token: string
    readonly expires: Date
    readonly email: string
  },
  readonly awsCredentials?: {
    readonly accessKeyId: string,
    readonly secretAccessKey: string,
    readonly sessionToken: string,
    readonly expires: Date
  },
}

const initialState: State = {
  isAuthenticated: false,
  googleCredentials: undefined,
  awsCredentials: undefined,
}

const reducerBlocks = {
  [authActions.UPDATE_TOKEN](state: State, { payload }: authActions.UpdateToken) {
    const { token, expires, email } = payload

    return {
      ...state,
      googleCredentials: { token, expires, email },
    }
  },

  [authActions.UPDATE_AWS_CREDS] (state: State, { payload }: authActions.UpdateAwsKeys) {
    const {
      accessKeyId, secretAccessKey, sessionToken, expires,
    } = payload

    return {
      ...state,
      awsCredentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
        expires,
      },
      isAuthenticated: true,
    }
  },

  [authActions.LOGOUT] () {
    return initialState
  },
}

export const reducer = createReducer<State, authActions.Actions>(reducerBlocks, initialState)
