import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  dialogPaper: {
    [theme.breakpoints.down('xs')]: {
      width: `calc(100% - ${theme.spacing(4) * 2}px)`,
      margin: theme.spacing(4),
    },
    overflowY: 'visible',
  },

  title: {
    // textAlign: 'center',
    '& > h6': {
      textTransform: 'none',
    },
  },

  contentPanel: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },

  actionsPanel: {
    padding: theme.spacing(2),
  },

  closeButton: {
    flex: '1 0 100%',

    '& > *': {
      flex: '1 0 50%',

      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
      },
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
})

export default makeStyles(styles)
