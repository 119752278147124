import { createSelector } from 'reselect'

import sigV4Client from '../../../utils/AWS/sigV4Client'
import { baseApiUrl } from '../../../utils/constants'
import { region } from '../../../utils/constants/AWS'
import { createCoreSelector } from '../../../utils/redux/selectors'
import { State } from './reducer'

import * as selectors from './selectors'

const selectFeature = createCoreSelector<State>('authentication')

export const isAuthenticated = createSelector(
  selectFeature,
  selectors.selectIsAuthenticated,
)
export const isAwsTokenExpired = createSelector(
  selectFeature,
  selectors.selectIsAwsExpired,
)
export const isGoogleExpired = createSelector(
  selectFeature,
  selectors.selectIsGoogleExpired,
)

export const getGoogleCredentials = createSelector(
  selectFeature,
  selectors.selectGoogleCredentials,
)
export const getAwsCredentials = createSelector(
  selectFeature,
  selectors.selectAwsCredentials,
)
export const selectGoogleEmailAddress = createSelector(
  selectFeature,
  selectors.selectGoogleEmailAddress,
)

export const getSignatureClient = createSelector(
  getAwsCredentials,
  (credentials: any) => sigV4Client.newClient({
    region,
    accessKey: credentials.accessKeyId,
    secretKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    endpoint: baseApiUrl,
  })
)
