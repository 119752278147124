import { createSelector } from 'reselect'
import { createCoreSelector } from '../../../utils/redux/selectors'

import { State } from './reducer'
import * as selectors from './selectors'

const selectFeature = createCoreSelector<State>('requestQueue')

export const getDeferredActions = createSelector(
  selectFeature,
  selectors.selectDeferredActions,
)
