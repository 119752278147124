import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  icon: {
    color: theme.palette.secondary.main,
    fontSize: 120,
    marginBottom: theme.spacing(6),

    [theme.breakpoints.down('xs')]: {
      fontSize: 80,
    },
  },

  text: {
    textTransform: 'none',
  },

  button: {
    marginTop: theme.spacing(2),
  },

  buttonIcon: {
    marginRight: theme.spacing(2),
  },
})

export default makeStyles(styles)
