import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { OpenProp } from '../../../interfaces/props'

import { PlainEventHandler } from '../../../interfaces/types'
import { countdownTime } from '../../../utils/constants'
import { SortMode } from '../../../utils/constants/enum'

import ConfirmationDialog from '../ConfirmationDialog'

import useStyles from './useStyles'

export type RefreshDataDialogProps = OpenProp & {
  onRefresh: PlainEventHandler
  onClose: PlainEventHandler
}

const RefreshDataDialog = ({
  open, onRefresh, onClose,
}: RefreshDataDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const confirmText = t('main:refresh_data_confirm_text')

  return (
    <ConfirmationDialog
      open={open}
      title={t('main:refresh_data_title')}
      color={SortMode.center}
      confirmText={confirmText}
      message={t('main:refresh_data_confirmation', { countdownTime })}
      label={(
        <Trans i18nKey='main:refresh_data_confirm_label'>
          <span className={classes.confirmText}>{{ confirmText }}</span>
        </Trans>
      )}
      confirmButtonLabel={t('main:refresh')}
      onConfirm={onRefresh}
      onClose={onClose}
    />
  )
}

RefreshDataDialog.propTypes = {
  open: PropTypes.bool,
  onRefresh: PropTypes.func,
  onClose: PropTypes.func,
}

RefreshDataDialog.defaultProps = {
  open: false,
  onRefresh: () => null,
  onClose: () => null,
}

export default RefreshDataDialog
