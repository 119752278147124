import React, { MouseEvent } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'

import { ClassNameProp, OnClickProp } from '../../../interfaces/props'

import useStyles from './useStyles'

export type RadioButtonProps =
  OnClickProp<MouseEvent>
  & ClassNameProp
  & { selected: boolean }

const RadioButton = ({
  className, selected, onClick,
}: RadioButtonProps) => {
  const classes = useStyles()

  return (
    <Paper
      className={clsx(classes.radioButton, className, selected && classes.selected)}
      onClick={onClick}
    >
      <Fade in={selected}>
        <span className={classes.inner} />
      </Fade>
    </Paper>
  )
}

RadioButton.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
}

RadioButton.defaultProps = {
  className: null,
  selected: false,
  onClick: () => null,
}

export default RadioButton
