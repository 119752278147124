import { createStyles, Theme } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  button: {
    boxShadow: 'none',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexBasis: '100%',
    },
  },

  icon: {
    marginRight: theme.spacing(2),
  },
})

export default styles
