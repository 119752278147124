import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Collapse from '@material-ui/core/Collapse'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ClassNameProp } from '../../../interfaces/props'
import { PlainEventHandler } from '../../../interfaces/types'

import useStyles from './useStyles'
import moment from 'moment';
import { Icon } from '../.raw';

export type NavBarProps = ClassNameProp & {
  onLogOff: PlainEventHandler
  onUpdate: PlainEventHandler
}

const NavBar = ({
  className, onLogOff, onUpdate,
}: NavBarProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleToggleOpen = () =>
    setOpen(!open)

  const handleClickAway = () => {
    if (open) setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <AppBar color='primary' className={className}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolsContainer}>
            <div className={classes.logoContainer}>
              <Icon name='trunkrs' className={classes.logo}/>
              <div>
                <div>Sorter-Switch</div>
                <div className={classes.dateLabel}>
                  Current Date: {moment().format('MMMM DD, YYYY')}
                </div>
              </div>
            </div>
            <IconButton
              className={clsx(classes.cogButton, open && classes.open)}
              onClick={handleToggleOpen}
            >
              <FontAwesomeIcon color='inherit' icon='cog' />
            </IconButton>
          </div>

          <Collapse className={classes.collapse} in={open}>
            <Button
              variant='contained'
              color='default'
              size='large'
              className={classes.menuButton}
              onClick={onUpdate}
            >
              {t('main:update_button')}
            </Button>

            <Button
              variant='contained'
              color='secondary'
              size='large'
              className={classes.menuButton}
              onClick={onLogOff}
            >
              {t('main:log_off')}
            </Button>
          </Collapse>
        </Toolbar>
      </AppBar>
    </ClickAwayListener>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  onLogOff: PropTypes.func,
  onUpdate: PropTypes.func,
}

NavBar.defaultProps = {
  className: null,
  onLogOff: () => null,
  onUpdate: () => null,
}

export default NavBar
