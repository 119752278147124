import Icon from '../Icon'
import clsx from 'clsx'
import defaultTo from 'lodash/defaultTo'
import get from 'lodash/get'
import React, {
  HTMLAttributes,
  memo,
  useCallback, useEffect,
  useMemo,
  useState,
} from 'react'

import ClickAway from '../utils/ClickAway'
import Tooltip from '../Tooltip'
import styles from './styles.module.scss'

export type Repick<T, U> = Pick<T, Exclude<any, U>>

const DropdownMenu = ({
  onChange,
  value,
  className,
  label,
  placeholder,
  disabled,
  children,
  placement = 'bottom',
  ...props,
                      }: Repick<React.DetailedHTMLProps<HTMLAttributes<any>, any>, 'onChange'> & {
                        placement?: 'top' | 'bottom'
                        onChange?: (value?: any) => void
                        value?: string | number
                        label?: string
                        disabled?: boolean
                      }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const contents = useMemo(() => React.Children.toArray(children), [children])
  const selectedNode = useMemo(
    () =>
      get(
        contents.find(child => value && get(child, 'props.value') === value),
        'props.children'
      ),
    [value, contents]
  )

  const showDropdown = useCallback(() => setIsDropdownVisible(true), [])
  const hideDropdown = useCallback(() => setIsDropdownVisible(false), [])

  const handleChange = useCallback(
    keyValue => () => {
      onChange && hideDropdown()
      onChange && onChange(keyValue)
    },
    [onChange]
  )

  const handleDropdownMenuAction = useCallback((callback: () => void) => {
    hideDropdown()
    callback && callback()
  },                                           [])

  const handleClear = useCallback(() => {
    hideDropdown()
    onChange && onChange()
  },                              [onChange])

  useEffect(() => {
    disabled && hideDropdown()
  },        [disabled])

  return (
    <div
      className={clsx(className, styles.container, disabled && styles.disabled)}
      {...props}
    >
      {
        placement === 'top' && (
          <ClickAway callback={hideDropdown} className={styles.dropdownTop}>
            <ul
              className={clsx(
                styles.dropdownContainer,
                isDropdownVisible && styles.visible
              )}
            >
              {useMemo(
                () =>
                  contents.map((child: any, i) => {
                    const nodeValue = get(child, 'props.value')
                    return React.cloneElement(child, {
                      ...get(child, 'props'),
                      key: `dropdown_menu_${nodeValue}_${i}`,
                      className: clsx(
                        get(child, 'props.className'),
                        nodeValue === value && styles.selected
                      ),
                      onClick: handleChange(nodeValue),
                    })
                  }),
                [value, contents]
              )}
            </ul>
          </ClickAway>
        )
      }
      <div className={clsx(styles.label, isDropdownVisible && styles.active)}>
        {defaultTo(value, '').toString().length > 0 && (
          <div className={styles.clearButton} onClick={handleClear}>
            <Tooltip text='Clear' placement='top'>
              <Icon name='close' />
            </Tooltip>
          </div>
        )}
        <div onClick={showDropdown}>
          {label && <span>{`${label}: `}</span>}
          {selectedNode ? selectedNode : <span>{placeholder}</span>}
          <Icon name={placement === 'top' ? 'caret-up' : 'caret-down'} />
        </div>
      </div>
      {
        placement === 'bottom' && (
          <ClickAway callback={hideDropdown}>
            <ul
              className={clsx(
                styles.dropdownContainer,
                isDropdownVisible && styles.visible
              )}
            >
              {useMemo(
                () =>
                  contents.map((child: any, i) => {
                    const nodeValue = get(child, 'props.value')
                    return React.cloneElement(child, {
                      ...get(child, 'props'),
                      key: `dropdown_menu_${nodeValue}_${i}`,
                      className: clsx(
                        get(child, 'props.className'),
                        nodeValue === value && styles.selected
                      ),
                      onClick: handleChange(nodeValue),
                    })
                  }),
                [value, contents]
              )}
            </ul>
          </ClickAway>
        )
      }
    </div>
  )
}

export default memo(DropdownMenu)
