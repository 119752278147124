import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { ClassNameProp } from '../../../interfaces/props'
import { SortMode } from '../../../utils/constants/enum'

import SwitchItem from './SwitchItem'
import useStyles from './useStyles'

export type ModeSwitcherProps = ClassNameProp & {
  mode: SortMode
  onSwitchMode: (mode: SortMode) => void
}

const ModeSwitcher = ({
  className, mode, onSwitchMode,
}: ModeSwitcherProps) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.menuHost, className)}>
      <SwitchItem
        mode={SortMode.region}
        onSelect={onSwitchMode}
        selected={mode === SortMode.region}
      />
      <SwitchItem
        mode={SortMode.center}
        onSelect={onSwitchMode}
        selected={mode === SortMode.center}
      />
      {/*<SwitchItem*/}
      {/*  mode={SortMode.missedConnection}*/}
      {/*  onSelect={onSwitchMode}*/}
      {/*  selected={mode === SortMode.missedConnection}*/}
      {/*/>*/}
    </div>
  )
}

ModeSwitcher.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.oneOf(Object.values(SortMode)),
  onSwitchMode: PropTypes.func,
}

ModeSwitcher.defaultProps = {
  className: null,
  mode: SortMode.region,
  onSwitchMode: () => null,
}

export default ModeSwitcher
