import get from 'lodash/get'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { SortMode } from '../../../utils/constants/enum'

const styles = (theme: Theme) => createStyles({
  inputContainer: {
    border: `1px solid ${fade(theme.palette.common.black, 0.12)}`,
    borderRadius: theme.shape.borderRadius,
  },

  input: {
    ...theme.typography.h6,
    padding: theme.spacing(2),
    textAlign: 'center',
  },

  [SortMode.region]: {
    color: get(theme, 'sortColors.region'),
  },

  [SortMode.center]: {
    color: get(theme, 'sortColors.center'),
  },
})

export default makeStyles(styles)
