import { ReducerRegistry } from '../../utils/redux/reducerRegistry'

import * as authReducer from './authentication/reducer'
import * as authActions from './authentication/actionNames'

import * as requestQueueReducer from './requestQueue/reducer'
import * as requestQueueActions from './requestQueue/actionNames'

export interface State {
  authentication: authReducer.State
  requestQueue: requestQueueReducer.State
}

export type CombinedActions = authActions.Actions
  & requestQueueActions.Actions

const registry = new ReducerRegistry<State, CombinedActions>()

registry.add('authentication', authReducer.reducer)
registry.add('requestQueue', requestQueueReducer.reducer)

export const reducer = registry.combine()
