import get from 'lodash/get'
export const mapServerText = (str: string = '') => {
  const dictionary = {
    'night': 'evening',
    'Night': 'Evening',
    'NIGHT': 'EVENING',
  }

  return get(dictionary, str, str)
}
