
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { ChildrenProp } from '../../interfaces/props'
import { googleClientId } from '../../utils/constants/external'

import connect from './connect'

export type GoogleAuthProviderProps = ChildrenProp & {
  startAuthTicker: (client: gapi.auth2.GoogleAuth) => void
}
export type GoogleAuthContextState = {
  isReady: boolean
  client?: gapi.auth2.GoogleAuth
}

export const GoogleAuthContext = React.createContext<GoogleAuthContextState>({
  isReady: false,
  client: undefined,
})

export class GoogleAuthProvider extends PureComponent<GoogleAuthProviderProps, GoogleAuthContextState> {
  public static propTypes = {
    startAuthTicker: PropTypes.func,
    children: PropTypes.node,
  }
  public static defaultProps = {
    startAuthTicker: () => null,
    children: null,
  }

  state = {
    isReady: false,
    client: undefined,
  }

  public componentDidMount(): void {
    const scriptEl = document.createElement('script')

    scriptEl.id = 'googlePlatform'
    scriptEl.src = `//apis.google.com/js/platform.js`
    scriptEl.async = true
    scriptEl.defer = true
    scriptEl.onload = this.handleLoadClient

    document.body.appendChild(scriptEl)
  }

  handleLoadClient = () => {
    const { startAuthTicker } = this.props

    /*eslint-disable no-undef*/
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: googleClientId,
      })

      const client = gapi.auth2.getAuthInstance()

      this.setState({ client, isReady: true })
      startAuthTicker(client)
    })
    /*eslint-enable no-undef*/
  }

  render() {
    const { children } = this.props

    return (
      <GoogleAuthContext.Provider value={this.state}>
        {children}
      </GoogleAuthContext.Provider>
    )
  }
}

export default connect(GoogleAuthProvider as any)
