import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  button: {
    height: 60,
    fontSize: 16,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12)',

    [theme.breakpoints.down('xs')]: {
      height: 48,
      fontSize: 14,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },

  googleIcon: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(5),

    [theme.breakpoints.down('xs')]: {
      width: 24,
      height: 24,
      marginRight: theme.spacing(3),
    },
  },
})

export default makeStyles(styles)
