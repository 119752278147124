import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = () => createStyles({
  button: {
    borderRadius: 0,
    boxShadow: 'none !important',
    height: 78,
  },
})

export default makeStyles(styles)
