import { DriverChuteResponse } from '../../../interfaces/models/driverChute'
import { createActionName } from '../../../utils/redux/createActionName'

import { FAILED, LOAD, SUCCESS } from '../../../utils/redux/defaultActionNames'
import { ApiAction, ApiFailureAction, ApiResponseAction } from '../../middleware/handleApiRequests/actions'

const actionNamespace = 'DRIVERCHUTES'

export const CHUTES = createActionName(actionNamespace, 'CHUTES')
export const CHUTES_LOAD = CHUTES + LOAD
export const CHUTES_SUCCESS = CHUTES + SUCCESS
export const CHUTES_FAILED = CHUTES + FAILED

export interface Load extends ApiAction<typeof CHUTES> {}
export interface LoadSuccess extends ApiResponseAction<typeof CHUTES_SUCCESS, DriverChuteResponse> {}
export interface LoadFailed extends ApiFailureAction<typeof CHUTES_FAILED> {}

export type Actions =
  Load |
  LoadSuccess |
  LoadFailed
