import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Drawer from '@material-ui/core/Drawer'
import { useTranslation } from 'react-i18next'

import { ChildrenProp } from '../../../interfaces/props'
import { PlainEventHandler } from '../../../interfaces/types'

import TrunkrsLogo from '../../icons/TrunkrsLogo'
import FilledButton from './FilledButton'

import useStyles from './useStyles'

export type NavDrawerProps = ChildrenProp & {
  onLogOff: PlainEventHandler
  onUpdate: PlainEventHandler
}

const NavDrawer = ({
  children, onLogOff, onUpdate,
}: NavDrawerProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Drawer
      variant='persistent'
      anchor='left'
      open={true}
      classes={{ paper: classes.drawerPaper }}
    >
      <TrunkrsLogo className={classes.logo} />
      <div className={classes.dateLabel}>
        Current Date: {moment().format('MMMM DD, YYYY')}
      </div>
      <span>
        {children}
      </span>

      <span className={classes.buttonContainer}>
        <FilledButton className={classes.updateButton} color='update' onClick={onUpdate}>
          {t('main:update_button')}
        </FilledButton>

        <FilledButton onClick={onLogOff}>
          {t('main:log_off')}
        </FilledButton>
      </span>
    </Drawer>
  )
}

NavDrawer.propTypes = {
  children: PropTypes.node,
  onLogOff: PropTypes.func,
  onUpdate: PropTypes.func,
}

NavDrawer.defaultProps = {
  onLogOff: () => null,
  onUpdate: () => null,
}

export default NavDrawer
