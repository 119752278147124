import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useStyles from './useStyles'

export type CenterModeActionsProps = {
  onRefresh: () => void
  onShowChuteList: () => void
}

const CenterModeActions = ({
  onRefresh, onShowChuteList,
}: CenterModeActionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.container}>
      <Button
        color='primary'
        variant='contained'
        className={classes.button}
        onClick={onRefresh}
      >
        <FontAwesomeIcon className={classes.icon} icon='sync' />
        {t('main:refresh_data')}
      </Button>
      <Button
        variant='contained'
        className={classes.button}
        onClick={onShowChuteList}
      >
        <FontAwesomeIcon className={classes.icon} icon='random' />
        {t('main:chute_list')}
      </Button>
    </Box>
  )
}

CenterModeActions.propTypes = {
  onRefresh: PropTypes.func,
  onShowChuteList: PropTypes.func,
}

CenterModeActions.defaultProps = {
  onRefresh: () => null,
  onShowChuteList: () => null,
}

export default CenterModeActions
