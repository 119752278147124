import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'

import { PlainEventHandler } from '../../../interfaces/types'
import { OpenProp } from '../../../interfaces/props'
import { countdownTime } from '../../../utils/constants'
import { SortMode } from '../../../utils/constants/enum'

import ConfirmationDialog from '../ConfirmationDialog'
import { useSelector } from 'react-redux';
import { getIsCapacityActionsLoading } from '../../../store/entities/sortMode';
import useStyles from './useStyles';

export type SwitchCapacitySlotDialogProps = OpenProp & {
  isLoading: boolean
  capacitySlotDateText: string
  switchCapacityError?: null|string
  showErrorMessage?: null|boolean
  onSubmit: (password: string) => void
  onClose: PlainEventHandler
}

const SwitchCapacitySlotDialog = ({
    open, onSubmit, onClose, capacitySlotDateText, switchCapacityError, showErrorMessage,
}: SwitchCapacitySlotDialogProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isLoading = useSelector(getIsCapacityActionsLoading)

  return (
      <ConfirmationDialog
          open={open}
          isLoading={isLoading}
          isPassword={true}
          title={'Change Capacity Slot'}
          color={SortMode.region}
          message={t('main:switch_capacity_dialog_confirmation', { countdownTime, capacitySlotDateText })}
          label={(
            <Trans i18nKey='main:switch_mode_dialog_label'>
              <span/>
            </Trans>
          )}
          confirmButtonLabel={`Change to ${capacitySlotDateText}`}
          confirmButtonProps={{ className: classes.dialogConfirmButtons }}
          closeButtonProps={{ className: classes.dialogConfirmButtons }}
          onConfirm={onSubmit}
          onClose={onClose}
          classN='switchCapacity'
          errorMessage={switchCapacityError}
          showErrorMessage={showErrorMessage}
      />
  )
}

SwitchCapacitySlotDialog.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  capacitySlotDateText: PropTypes.string,
  switchCapacityError: PropTypes.string,
}

SwitchCapacitySlotDialog.defaultProps = {
  open: false,
  isLoading: false,
  switchCapacityError: null,
  onSubmit: () => null,
  onClose: () => null,
}

export default SwitchCapacitySlotDialog
