import { ChangeEvent, useState } from 'react'

export type UseChangeHookValue = [
  string | number | undefined,
  (event: ChangeEvent<HTMLInputElement>) => void,
  (value: string) => void
]

export function useChangeEvent(
  initialValue: string | number = '',
): UseChangeHookValue {
  const [value, setValue] = useState(initialValue)

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) =>
    setValue(event.currentTarget.value.toLocaleLowerCase())

  return [value, handleOnChange, setValue]
}
