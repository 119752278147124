import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter } from 'react-router-dom'

import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import CssBaseline from '@material-ui/core/CssBaseline'

import { Provider as StoreProvider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import GoogleAuthProvider from './GoogleAuthProvider'

import { ChildrenProp } from '../interfaces/props'

import configureStore from '../store/configure'

import theme from './theme'

import './i18n'
import './fontAwesome'

export type AppProviderProps = ChildrenProp

const store = configureStore()
const persistor = persistStore(store)

export const AppProvider = ({
    children,
}: AppProviderProps) => (
    <StoreProvider store={store}>
      <GoogleAuthProvider>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <BrowserRouter>
              {children}
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </GoogleAuthProvider>
    </StoreProvider>
)

AppProvider.propTypes = {
  children: PropTypes.node,
}

AppProvider.defaultProps = {
  children: null,
}

export default AppProvider
