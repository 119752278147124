import React, { MouseEvent } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Button from '@material-ui/core/Button'

import { ChildrenProp, ClassNameProp, OnClickProp } from '../../../../interfaces/props'

import useStyles from './useStyles'

export type FilledButtonProps = OnClickProp<MouseEvent>
  & ChildrenProp
  & ClassNameProp
  & {
    color: 'default' | 'update'
  }

const FilledButton = ({
  color, className, onClick, children,
}: FilledButtonProps) => {
  const classes = useStyles()

  return (
    <Button
      color={color === 'default' ? 'secondary' : 'default'}
      variant='contained'
      className={clsx(classes.button, className)}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

FilledButton.propTypes = {
  color: PropTypes.oneOf(['default', 'update']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

FilledButton.defaultProps = {
  color: 'default',
  className: null,
  onClick: () => null,
  children: null,
}

export default FilledButton
