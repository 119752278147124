/* eslint-disable import/prefer-default-export */
import { ReactComponent as TrunkrsLogo } from './library/trunkrs-logo.svg'
import { ReactComponent as LoadingIcon } from './library/loading-icon.svg'
import { ReactComponent as PlusIcon } from './library/plus-icon.svg'
import { ReactComponent as EditIcon } from './library/edit-icon.svg'
import { ReactComponent as TrashIcon } from './library/trash-icon.svg'
import { ReactComponent as CloseIcon } from './library/close-icon.svg'
import { ReactComponent as CheckCircleIcon } from './library/check-circle-icon.svg'
import { ReactComponent as SearchIcon } from './library/search-icon.svg'
import { ReactComponent as HouseIcon } from './library/house-icon.svg'
import { ReactComponent as ChevronDownIcon } from './library/chevron-down.svg'
import { ReactComponent as RetryIcon } from './library/retry-icon.svg'
import { ReactComponent as ElipseIcon } from './library/elipse.svg'
import { ReactComponent as ElipseFilledIcon } from './library/elipse-filled.svg'
import { ReactComponent as TruckIcon } from './library/truck.svg'
import { ReactComponent as CheckIcon } from './library/check.svg'
import { ReactComponent as PhoneIcon } from './library/phone.svg'
import { ReactComponent as PackageIcon } from './library/package.svg'
import { ReactComponent as OrganisationIcon } from './library/organisation.svg'
import { ReactComponent as HubInventoryIcon } from './library/hub-inventory.svg'
import { ReactComponent as DangerIcon } from './library/danger.svg'
import { ReactComponent as WarningIcon } from './library/warning.svg'
import { ReactComponent as SuccessIcon } from './library/success.svg'
import { ReactComponent as InfoCircleIcon } from './library/info-circle.svg'
import { ReactComponent as ArrowRightIcon } from './library/arrow-right.svg'
import { ReactComponent as SubtractIcon } from './library/subtract.svg'
import { ReactComponent as SwapIcon } from './library/swap-icon.svg'
import { ReactComponent as PaletteIcon } from './library/palette.svg'
import { ReactComponent as ViewIcon } from './library/view.svg'
import { ReactComponent as HideIcon } from './library/hide.svg'
import { ReactComponent as CaretDownIcon } from './library/caret-down.svg'
import { ReactComponent as CaretUpIcon } from './library/caret-up-icon.svg'
import { ReactComponent as UserIcon } from './library/user.svg'
import { ReactComponent as LogoutIcon } from './library/logout.svg'
import { ReactComponent as BarcodeIcon } from './library/barcode-icon.svg'
import { ReactComponent as EmptyResultIcon } from './library/empty-result-icon.svg'
import { ReactComponent as CalendarCheckIcon } from './library/calendar-check-icon.svg'
import { ReactComponent as SwitchToDayIcon } from './library/switch-to-day-icon.svg'
import { ReactComponent as SwitchToEveningIcon } from './library/switch-to-evening-icon.svg'


export const IconLibrary = {
  trunkrs: TrunkrsLogo,
  loading: LoadingIcon,
  barcode: BarcodeIcon,
  plus: PlusIcon,
  edit: EditIcon,
  trash: TrashIcon,
  swap: SwapIcon,
  'calendar-check': CalendarCheckIcon,
  close: CloseIcon,
  'check-circle': CheckCircleIcon,
  'info-circle': InfoCircleIcon,
  warning: WarningIcon,
  danger: DangerIcon,
  success: SuccessIcon,
  search: SearchIcon,
  phone: PhoneIcon,
  house: HouseIcon,
  'chevron-down': ChevronDownIcon,
  retry: RetryIcon,
  elipse: ElipseIcon,
  'elipse-filled': ElipseFilledIcon,
  truck: TruckIcon,
  check: CheckIcon,
  package: PackageIcon,
  organisation: OrganisationIcon,
  'hub-inventory': HubInventoryIcon,
  'arrow-right': ArrowRightIcon,
  subtract: SubtractIcon,
  palette: PaletteIcon,
  view: ViewIcon,
  hide: HideIcon,
  'caret-down': CaretDownIcon,
  'caret-up': CaretUpIcon,
  user: UserIcon,
  logout: LogoutIcon,
  'empty-result': EmptyResultIcon,
  'switch-day': SwitchToDayIcon,
  'switch-night': SwitchToEveningIcon,
}
