import React from 'react'
import { useTranslation } from 'react-i18next'

import Dialog from '../../Base/Dialog'
import CountDownTimer, { CountdownTimerProps } from '../../Overview/CountdownTimer'

export type CountdownDialogProps = CountdownTimerProps

const CountdownDialog = ({ ...countDownProps }: CountdownDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={true}
      title={t('main:please_wait_countdown')}
    >
      <Dialog.Content>
        <CountDownTimer {...countDownProps} />
      </Dialog.Content>
    </Dialog>
  )
}

CountdownDialog.propTypes = {
  ...CountDownTimer.propTypes,
}
CountdownDialog.defaultProps = {
  ...CountDownTimer.defaultProps,
}

export default CountdownDialog
