import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'

const styles = () => createStyles({
  confirmText: {
    textTransform: 'uppercase',
    fontWeight: 800,
  },
})

export default makeStyles(styles)
