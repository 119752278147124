import localForage from 'localforage'
import createFilter from 'redux-persist-transform-filter'

localForage.config({
  name: 'Trunkrs',
  storeName: 'sortr-switch',
})

const coreFilter = createFilter(
  'core',
  ['authentication'],
  ['authentication']
)

export default {
  key: 'sortr-switch',
  storage: localForage,
  whitelist: ['core'],
  transforms: [
    coreFilter,
  ],
}
