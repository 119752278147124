import { applyMiddleware, compose, createStore, DeepPartial, Store } from 'redux'
import { persistReducer, PersistPartial } from 'redux-persist'
import thunk from 'redux-thunk'

import { isDevelopment } from '../utils/constants'
import { handleApiRequests } from './middleware/handleApiRequests'
import reducer, { AppState } from './reducer'

import persistConfig from './persistConfig'

const middleware: any[] = [
  thunk,
  handleApiRequests,
]

type RootState = AppState & PersistPartial

function configureStore(initialState: DeepPartial<RootState> = {}): Store<RootState> {
  const composeWithDevToolsExtension = isDevelopment
    // @ts-ignore
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  const composeEnhancers = (typeof composeWithDevToolsExtension === 'function')
                           ? composeWithDevToolsExtension
                           : compose

  const composedMiddleware = composeEnhancers(applyMiddleware(...middleware))
  const persistedRoot = persistReducer(persistConfig, reducer)

  return createStore(persistedRoot, initialState, composedMiddleware)
}

export default configureStore
