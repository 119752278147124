import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  modal: {
    display: 'none',

    '@media (min-width: 320px) and (max-width: 900px) and (orientation: landscape)': {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      top: '50%',
      left: '50%',
      width: 300,
      height: 300,
      padding: theme.spacing(6),
      transform: 'translate(-50%, -50%)',
      backgroundColor: fade(theme.palette.common.black, .8),
      borderRadius: theme.shape.borderRadius,
      zIndex: theme.zIndex.appBar + 1,
    },
  },

  icon: {
    fontSize: 160,
    color: theme.palette.common.white,
    transform: 'rotate(0deg)',
    animationName: 'rotatePhone',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: theme.transitions.easing.easeInOut,
  },

  blurredContent: {
    width: '100%',
    height: '100%',

    '@media (min-width: 320px) and (max-width: 900px) and (orientation: landscape)': {
      '& *': {
        filter: 'blur(2px)',
      },
    },
  },
})

export default makeStyles(styles)
