import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

import { PlainEventHandler } from '../../../interfaces/types'
import connect from './connect'
import { SortMode } from '../../../utils/constants/enum';

export type EntityLoaderProps = {
  currentMode: SortMode,
  canLoadChutes: boolean
  hasFetchedMode: boolean
  loadMode: PlainEventHandler
  loadChutes: PlainEventHandler
  loadCapacitySlots: PlainEventHandler
  startModeTicker: PlainEventHandler
}

export const EntityLoader = ({
                               canLoadChutes, hasFetchedMode, loadMode, loadChutes, loadCapacitySlots, startModeTicker,
                             }: EntityLoaderProps) => {
  const lastCallRef = useRef(0)

  useEffect(
    () => { !hasFetchedMode && loadMode() },
    [hasFetchedMode, loadMode],
  )

  useEffect(
    () => {
      const { current: lastCall } = lastCallRef
      const hasBeenFiveSeconds = (Number(new Date()) - lastCall) > 5000

      if (canLoadChutes && hasBeenFiveSeconds) {
        lastCallRef.current = Number(new Date())
        loadChutes()
      }

    },
    [canLoadChutes, lastCallRef, loadChutes, loadCapacitySlots],
  )

  useEffect(startModeTicker, [startModeTicker])

  useEffect(loadCapacitySlots, [loadCapacitySlots])

  return null
}

EntityLoader.propTypes = {
  loadMode: PropTypes.func.isRequired,
  loadChutes: PropTypes.func.isRequired,
  loadCapacitySlots: PropTypes.func.isRequired,
  startModeTicker: PropTypes.func.isRequired,
  canLoadChutes: PropTypes.bool,
  hasFetchedMode: PropTypes.bool,
} as any

EntityLoader.defaultProps = {
  canLoadChutes: false,
  hasFetchedMode: false,
} as any

export default connect(EntityLoader)
