import { ActionCreator } from 'redux'
import get from 'lodash/get'
import { SortMode } from '../../../utils/constants/enum'
import { AsyncAction, Dispatch, GetState } from '../../../utils/redux/thunk'

import * as fromSortMode from '.'
import * as fromAuth from '../../core/authentication'

import {
  CAPACITY_SLOTS,
  CapacitySlots,
  COMPLETE_SORTING,
  CompleteSorting,
  LoadMode,
  MISSED_CONNECTION_MODE,
  MissedConnectionMode,
  MODE,
  MODE_RETRY,
  RetryMode,
  SWITCH_CAPACITY_SLOT,
  UPDATE_MODE,
  UpdateMode,
  CLOSE_CAPACITY_SLOT,
  SwitchCapacitySlotLoad,
  InitCapacitySlotActions,
  INIT_CAPACITY_SLOT_ACTIONS,
  CloseCapacitySlotLoad, SWITCH_CLOSE_CAPACITY_SLOT,
} from './actionNames'
import { mapSortModeToServerMode } from './helpers'
import moment from 'moment';
import { createSetActiveCountdownAction } from '../../features/overview/actions';

export const createLoadAction: ActionCreator<AsyncAction<LoadMode>> =
  () => (dispatch: Dispatch, getState: GetState) => {
    const hasFetched = fromSortMode.hasFetched(getState())
    dispatch({
      type: MODE,
      request: {
        method: 'GET',
        url: 'current-mode',
      },
      payload: { hasFetched },
    })
  }

export const createUpdateAction: ActionCreator<AsyncAction<UpdateMode>> = ({ mode, password }: {
  mode: SortMode
  password: string
}) => (dispatch: Dispatch, getState: GetState) => {
  const email = fromAuth.selectGoogleEmailAddress(getState())
  const capacitySlot = fromSortMode.getCurrentCapacitySlot(getState())

  const networkType = get(capacitySlot, 'networkType', '').toUpperCase()
  const capacitySlotDate = get(capacitySlot, 'date')

  dispatch({
    type: UPDATE_MODE,
    request: {
      method: 'PUT',
      url: 'switch-mode',
      data: {
        email,
        password,
        networkType,
        mode: mapSortModeToServerMode(mode),
        capacitySlotDate: moment(capacitySlotDate).format('YYYY-MM-DD'),
      },
    },
  })

  dispatch(createSetActiveCountdownAction(true))
}

export const createRetryModeAction: ActionCreator<RetryMode> = () => ({
  type: MODE_RETRY,
})

export const doRetryFetchingMode: ActionCreator<AsyncAction<LoadMode>> =
  () => (dispatch: Dispatch) => {
    dispatch(createRetryModeAction())
    dispatch(createLoadAction())
  }

export const doCompleteSortMode: ActionCreator<AsyncAction<CompleteSorting>> =
  (payload: { password: string }) => (dispatch, getState) => {
    const email = fromAuth.selectGoogleEmailAddress(getState())

    dispatch({
      type: COMPLETE_SORTING,
      request: {
        method: 'PUT',
        url: 'sorting-done',
        data: { ...payload, email },
      },
    })
  }

export const doMissedConnectionMode: ActionCreator<AsyncAction<MissedConnectionMode>> =
  ({ password }: { password: string }) => (dispatch, getState) => {
    const email = fromAuth.selectGoogleEmailAddress(getState())

    dispatch({
      type: MISSED_CONNECTION_MODE,
      request: {
        method: 'PUT',
        url: 'missed-connection',
        data: {
          email,
          password,
          mode: SortMode.missedConnection.toUpperCase(),
        },
      },
    })
  }

export const createLoadCapacityListAction: ActionCreator<AsyncAction<CapacitySlots>> =
  () => (dispatch) => {
    dispatch({
      type: CAPACITY_SLOTS,
      request: {
        method: 'GET',
        url: 'capacity-slots',
      },
    })
  }

export const initSwitchCapacityAction: ActionCreator<AsyncAction<InitCapacitySlotActions>> =
  () => (dispatch) => {
    dispatch({
      type: INIT_CAPACITY_SLOT_ACTIONS,
    })
  }

export const createSwitchCapacitySlotAction: ActionCreator<AsyncAction<SwitchCapacitySlotLoad>> = ({ capacitySlotDate, networkType, password }: {
  capacitySlotDate: string,
  networkType: string,
  password: string
}) => (dispatch: Dispatch, getState: GetState) => {
  const email = fromAuth.selectGoogleEmailAddress(getState())
  dispatch({
    type: SWITCH_CAPACITY_SLOT,
    request: {
      method: 'PUT',
      url: 'switch-mode',
      data: {
        email,
        password,
        networkType: networkType.toUpperCase(),
        capacitySlotDate: moment(capacitySlotDate).format('YYYY-MM-DD'),
        mode: mapSortModeToServerMode(SortMode.region),
      },
    },
  })
}

export const createCloseCapacitySlotAction: ActionCreator<AsyncAction<CloseCapacitySlotLoad>> = ({ capacitySlotDate, networkType, password }: {
  capacitySlotDate: string,
  networkType: string,
  password: string
}) => (dispatch: Dispatch, getState: GetState) => {
  const currentCapacitySlot = fromSortMode.getCurrentCapacitySlot(getState())
  const currentCapacitySlotNetworkType = get(currentCapacitySlot, 'networkType', '').toUpperCase()
  const currentCapacitySlotNetworkTypeDate = moment(get(currentCapacitySlot, 'capacitySlotDate', '')).format('YYYY-MM-DD')
  const email = fromAuth.selectGoogleEmailAddress(getState())
  const nextAvailableCapacitySlot = fromSortMode.getNextAvailableCapacitySlot(getState())

  if (currentCapacitySlot) {
    dispatch({
      type: SWITCH_CLOSE_CAPACITY_SLOT,
      payload: {
        capacitySlot: currentCapacitySlot,
      },
    });

    if (nextAvailableCapacitySlot) {
      (dispatch({
        type: SWITCH_CLOSE_CAPACITY_SLOT,
        request: {
          method: 'PUT',
          url: 'switch-mode',
          data: {
            email,
            password,
            networkType: nextAvailableCapacitySlot.networkType.toUpperCase(),
            capacitySlotDate: moment(nextAvailableCapacitySlot.date).format('YYYY-MM-DD'),
            mode: mapSortModeToServerMode(SortMode.region),
          },
        },
      }) as Promise<any>).then((result) => {
        const hasError = !!fromSortMode.getCloseCapacityError(getState())
        if (!hasError) {
          dispatch({
            type: CLOSE_CAPACITY_SLOT,
            request: {
              method: 'POST',
              url: 'capacity-slots/close',
              data: {
                password,
                networkType: currentCapacitySlotNetworkType,
                date: currentCapacitySlotNetworkTypeDate,
              },
            },
          })
        }
      }
      )
    }
  }
}
