import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  timer: {
    fontSize: 120,
    textAlign: 'center',
  },

  seconds: {
    marginTop: theme.spacing(4),
  },
})

export default makeStyles(styles)
