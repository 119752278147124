/* tslint:disable:max-line-length */
import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const TrunkrsLogo = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox='0 0 1471 180'>
        <path fill='#ffffff' d='M426.26,31.7V169.78H393.69V31.7H319.94l.83-22.47H499.45L500,31.7Z'/>
        <path fill='#ffffff' d='M492.75,169.78V43.69l23.37-1.28,1.4,11.77,1.39,17.34h.55c12-19.26,32.85-30.82,59.57-30.82a98.42,98.42,0,0,1,19.2,1.92l-1.67,22.05a82.75,82.75,0,0,0-19.48-2.14c-30.9,0-54.83,18.2-54.83,46v61.22Z'/>
        <path fill='#ffffff' d='M774,124.61c0,29.55-24.49,47.74-75.7,47.74-50.94,0-75.43-18.41-75.43-47.74v-82h29.5v81.14c0,17.76,15,28.47,45.93,28.47s46.2-10.49,46.2-28.47V42.62H774Z'/>
        <path fill='#ffffff' d='M934.5,169.78V91c0-21-15.86-30.4-45.64-30.4-16.42,0-36.74,5.57-46.48,15.63v93.55h-29.5V43.26L833.47,42,839,56.75c14.75-10.49,35.9-16.48,57.61-16.48,42,0,67.36,17.34,67.36,50.73v78.78Z'/>
        <path fill='#ffffff' d='M1033,92.29h30.89l57.89-49.67h35.91l-69,58.23,71.81,68.93h-35.91l-61-58.87H1033v58.87h-29.51V1.73L1033,.45Z'/>
        <path fill='#ffffff' d='M1184.39,169.78V43.69l23.37-1.28,1.4,11.77,1.39,17.34h.55c12-19.26,32.85-30.82,59.57-30.82a98.42,98.42,0,0,1,19.2,1.92l-1.67,22.05a82.75,82.75,0,0,0-19.48-2.14c-30.9,0-54.83,18.2-54.83,46v61.22Z'/>
        <path fill='#ffffff' d='M1371.14,172.57c-23.66,0-45.64-4.29-61-12.21l4.17-18.84c15,8.35,38.13,12.64,57.06,12.64,28.11,0,39.52-9.42,39.52-20.13,0-27.83-100.47-13.06-100.47-57.16,0-21.83,22.54-36.39,67.07-36.39,18.93,0,37,2.57,53.44,7.92l-2.78,18.41a184.92,184.92,0,0,0-51.77-7.7c-23.1,0-37.57,6.63-37.57,16.69,0,25.91,99.64,10.92,99.64,55.88C1438.5,154.8,1418.74,172.57,1371.14,172.57Z'/>
        <path fill='#00d163' d='M16.41,58.14a4.47,4.47,0,0,0,3,7.78l80.7-.22a7.1,7.1,0,0,0,4.77-1.85L165,9.23l-88.91.24a16,16,0,0,0-10.71,4.16Z'/>
        <path fill='#ffffff' d='M278.55,9.25l-97.11.23A8.25,8.25,0,0,0,176,11.6L118.4,63.71a6.81,6.81,0,0,0-2.23,5V109a4.26,4.26,0,0,1-4.25,4.26l-37.14.09a15.46,15.46,0,0,0-10.35,4L15.3,162a4.47,4.47,0,0,0,3,7.78l95.6-.22a5.88,5.88,0,0,0,3.91-1.52l58.23-52.7a6.82,6.82,0,0,0,2.24-5V70.06a4.26,4.26,0,0,1,4.24-4.26l39.84-.09a8.47,8.47,0,0,0,5.66-2.2l52.9-48.08A3.55,3.55,0,0,0,278.55,9.25Z'/>
        <path fill='#ffffff' d='M1456,37.72a8.79,8.79,0,1,1-8.76-8.54A8.6,8.6,0,0,1,1456,37.72Zm-15.38,0a6.62,6.62,0,0,0,6.67,6.83,6.54,6.54,0,0,0,6.52-6.77,6.6,6.6,0,1,0-13.19-.06Zm5.27,4.49h-2V33.66a16.94,16.94,0,0,1,3.28-.26,5.35,5.35,0,0,1,3,.62,2.39,2.39,0,0,1,.84,1.88,2.18,2.18,0,0,1-1.78,2V38a2.5,2.5,0,0,1,1.57,2.08,6.56,6.56,0,0,0,.62,2.14h-2.13a7,7,0,0,1-.68-2.09c-.16-.94-.68-1.35-1.78-1.35h-.93Zm0-4.85h.94c1.09,0,2-.37,2-1.25s-.58-1.3-1.83-1.3a4.91,4.91,0,0,0-1.09.1Z'/>
    </SvgIcon>
)

TrunkrsLogo.propTypes = SvgIcon.propTypes
TrunkrsLogo.defaultProps = SvgIcon.defaultProps

export default TrunkrsLogo
