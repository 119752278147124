import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStyles from './useStyles'

const CloseCapacityError = ({ errorMessage }: {errorMessage: string}) => {
  const classes = useStyles()

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      maxWidth={500}
    >
      <FontAwesomeIcon
        className={classes.icon}
        icon='exclamation-circle'
      />

      <Typography className={classes.text} color='secondary' variant='h6' align='center'>
        {errorMessage}
      </Typography>
    </Box>
  )
}

export default CloseCapacityError
