import { createReducer } from '../../../utils/redux/createReducer'
import { LOGOUT } from '../../core/authentication/actionNames'

import * as overviewActions from './actionNames'
import * as sortModeActions from '../../entities/sortMode/actionNames'
import { isMoreThanCountdownAgo } from './helpers'

export interface State {
  readonly activeCountdown: boolean
  readonly lastCountdownStamp: number
  readonly isRefreshing: boolean
}

export const initialState: State = {
  activeCountdown: false,
  lastCountdownStamp: 0,
  isRefreshing: false,
}

const reducerBlocks = {
  [overviewActions.SET_COUNTDOWN](state: State, { enabled }: overviewActions.SetCountdown) {
    return { ...state, activeCountdown: enabled }
  },

  [overviewActions.REFRESH_DATA_LOAD] (state: State) {
    return { ...state, isRefreshing: true }
  },

  [overviewActions.REFRESH_DATA_SUCCESS](state: State) {
    return { ...state, isRefreshing: false }
  },

  [overviewActions.REFRESH_DATA_FAILED](state: State) {
    return { ...state, isRefreshing: false }
  },

  [sortModeActions.MODE_SUCCESS] (state: State, action: sortModeActions.LoadModeSuccess) {
    const { lastCountdownStamp: lastStamp } = state
    const { payload: { result: { stamp } } } = action

    const activeCountdown = stamp !== lastStamp
      && !isMoreThanCountdownAgo(stamp)

    return {
      ...state,
      activeCountdown,
      lastCountdownStamp: stamp,
    }
  },

  [sortModeActions.UPDATE_MODE_FAILED](state: State) {
    return { ...state, activeCountdown: false, lastCountdownStamp: 0 }
  },

  [LOGOUT]() {
    return initialState
  },
}

export const reducer = createReducer<State, overviewActions.Actions>(reducerBlocks, initialState)
