import get from 'lodash/get'

import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'

const styles = (theme: Theme) => createStyles({
  closeButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },

  confirmButton: {
    backgroundColor: get(theme, 'success'),
    boxShadow: 'none !important',
    color: theme.palette.common.white,

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },

    [theme.breakpoints.up('sm')]: {
      maxWidth: '62%',
    },

    '&:active, &:hover': {
      backgroundColor: darken(get(theme, 'success', '#fff'), 0.25),
    },

    '&:disabled': {
      backgroundColor: '#F5F5F5',
    },
  },
})

export default makeStyles(styles)
