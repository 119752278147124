import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  dialog: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
    },
    overflow: 'auto',
  },

  paper: {
    border: '4px solid green',
  },

  message: {
    // maxWidth: 400,
  },

  targetMode: {
    textTransform: 'uppercase',
    fontWeight: 800,
  },

  password: {
    '& > input': {
      textAlign: 'left',
      color:  '#220C4A',
    },
  },

  errorInput: {
    border: '2px solid #FF3A37',
  },

  error: {
    marginTop: '10px',
    display: 'inline-block',
    color: '#FF3A37',
  },

  loadingIcon: {
    width: 40,
    height: 40,

    '& > circle' : {
      stroke: '#13d2fc',
    },
  },
})

export default makeStyles(styles)
