import { ReducerRegistry } from '../../utils/redux/reducerRegistry'

import * as driverChuteReducer from './driverChutes/reducer'
import * as driverChuteAction from './driverChutes/actionNames'

import * as sortModeReducer from './sortMode/reducer'
import * as sortModeActions from './sortMode/actionNames'

export interface State {
  driverChutes: driverChuteReducer.State,
  sortMode: sortModeReducer.State,
}

export type CombinedActions =
  driverChuteAction.Actions |
  sortModeActions.Actions

const registry = new ReducerRegistry<State, CombinedActions>()

registry.add('driverChutes', driverChuteReducer.reducer)
registry.add('sortMode', sortModeReducer.reducer)

export const reducer = registry.combine()
