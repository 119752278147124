import React, { useContext } from 'react'
import { GoogleAuthContext } from '../../AppProvider/GoogleAuthProvider'

import TrunkrsIcon from '../../components/icons/TrunkrsIcon'
import GoogleButton from '../../components/Login/GoogleButton'
import LoginPaper from '../../components/Login/LoginPaper'

import useStyles from './useStyles'

export const RouteLogin = () => {
  const classes = useStyles()
  const { isReady, client } = useContext(GoogleAuthContext)

  const handleLoginClick = async () => {
    // Meh
    if (!client) return

    await client.signIn({ ux_mode: 'popup' })
  }

  return (
    <div className={classes.container}>
        <LoginPaper className={classes.paper}>
          <TrunkrsIcon className={classes.icon} />

          <GoogleButton
            disabled={!isReady}
            onClick={handleLoginClick}
          />
        </LoginPaper>
    </div>
  )
}

RouteLogin.propTypes = {
}

RouteLogin.defaultProps = {
}

export default RouteLogin
