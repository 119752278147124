import { ActionCreator } from 'redux'
import { CHUTES, Load } from './actionNames'

export const createLoadAction: ActionCreator<Load> = () => ({
  type: CHUTES,
  request: {
    method: 'GET',
    url: 'driver-chute',
  },
})
