import get from 'lodash/get'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  bar: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    minHeight: get(theme, 'navSizes.drawer'),
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
  },

  iconBubble: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    top: -30,
    left: '50%',
    width: get(theme, 'navSizes.drawer'),
    height: get(theme, 'navSizes.drawer') / 2,
    backgroundColor: theme.palette.primary.main,
    transform: 'translateX(-50%)',
    borderTopLeftRadius: get(theme, 'navSizes.drawer') / 2,
    borderTopRightRadius: get(theme, 'navSizes.drawer') / 2,
  },

  chevron: {
    fontSize: 16,
    color: theme.palette.common.white,
    transition: theme.transitions.create(
      ['transform'],
      { duration: theme.transitions.duration.standard },
    ),
  },

  open: {
    transform: 'rotate(180deg)',
  },

  title: {
    textAlign: 'center',
    lineHeight: `${get(theme, 'navSizes.drawer')}px`,
  },

  switcher: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  },
})

export default makeStyles(styles)
