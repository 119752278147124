import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  contentPanel: {
    padding: 0,
  },

  list: {
    maxHeight: '50vh',
    paddingBottom: 0,
  },

  item: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },

  itemText: {
    fontWeight: 800,
  },

  itemAction: {
    right: theme.spacing(4),
  },
})

export default makeStyles(styles)
