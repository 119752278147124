import { createSelector } from 'reselect'

import { createFeatureSelector } from '../../../utils/redux/selectors'
import { State } from './reducer'

import * as selectors from './selectors'

const selectFeature = createFeatureSelector<State>('overview')

export const hasCountdownActive = createSelector(
  selectFeature,
  selectors.selectActiveCountDown,
)
export const getTimestampDifference = createSelector(
  selectFeature,
  selectors.selectTimestampDiff,
)
export const getCountdownSeconds = createSelector(
  selectFeature,
  selectors.selectCountdownSeconds,
)
