import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'

import ConfirmationDialog from '../ConfirmationDialog'

import styles from './styles'

export interface CompleteSortingDialogProps extends WithStyles<typeof styles>{
  open: boolean
  isLoading: boolean
  onCompleteSorting?: (password: string) => void | Promise<void>
  onClose?: () => void | Promise<void>
}

const CompleteSortingDialog: React.FC<CompleteSortingDialogProps> = ({
  classes, open, isLoading, onCompleteSorting, onClose,
}) => {
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
      isPassword={true}
      open={open}
      isLoading={isLoading}
      confirmText={' '}
      title={t('main:complete_sorting_confirm')}
      message={t('main:complete_sorting_dialog_confirmation')}
      label={(
        <Trans i18nKey='main:complete_sorting_dialog_label'>
          <span className={classes.targetMode} />
        </Trans>
      )}
      confirmButtonLabel={t('main:complete_sorting_confirm')}
      onConfirm={onCompleteSorting}
      onClose={onClose}
    />
  )
}

export default withStyles(styles)(CompleteSortingDialog)
