import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = () => createStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },

  icon: {
    width: 120,
    height: 72,
    marginBottom: 60,
  },

  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
})

export default makeStyles(styles)
