import { AnyAction, combineReducers, Reducer } from 'redux'

export class ReducerRegistry<TCombinedState, TCombinedActions extends AnyAction> {
  private readonly reducerMap: { [reducerName: string]: Reducer } = {}

  add<TState, TActions extends AnyAction>(name: keyof TCombinedState, reducer: Reducer<TState, TActions>): void {
    Object.assign(this.reducerMap, { [name]: reducer })
  }

  combine(): Reducer<TCombinedState, TCombinedActions> {
    return combineReducers(this.reducerMap) as any
  }
}
