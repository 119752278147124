import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SortMode } from '../../../utils/constants/enum'
import ModeSwitcher from '../ModeSwitcher'

import useStyles from './useStyles'

export type ModeSwitchDrawerProps = {
  mode: SortMode
  onSwitchMode: (mode: SortMode) => void
}

const ModeSwitchDrawer = (props: ModeSwitchDrawerProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleToggleOpen = () =>
    setOpen(!open)

  const handleClickAway = () =>
    setOpen(false)

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper
        color='primary'
        className={classes.bar}
        onClick={handleToggleOpen}
      >
        <div className={classes.iconBubble}>
          <FontAwesomeIcon
            icon='chevron-up'
            className={clsx(classes.chevron, open && classes.open)}
          />
        </div>

        <Typography className={classes.title} variant='h4' color='textSecondary'>
          {t('main:choose_sort_mode')}
        </Typography>

        <Collapse in={open}>
          <ModeSwitcher className={classes.switcher} {...props} />
        </Collapse>
      </Paper>
    </ClickAwayListener>
  )
}

ModeSwitchDrawer.propTypes = {
  mode: PropTypes.oneOf(Object.values(SortMode)),
  onSwitchMode: PropTypes.func,
}

ModeSwitchDrawer.defaultProps = {
  mode: SortMode.region,
  onSwitchMode: () => null,
}

export default ModeSwitchDrawer
