import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'

import DialogExt from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import { ChildrenProp, ClassNameProp, OpenProp } from '../../../interfaces/props'

import Actions from './Actions'
import useStyles from './useStyles'

export type DialogProps = ChildrenProp & OpenProp & ClassNameProp & {
  title?: ReactNode | string
}

const Dialog = ({
  className, title, open, children,
}: DialogProps) => {
  const classes = useStyles()

  return (
    <DialogExt
      fullWidth={true}
      maxWidth='sm'
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      classes={{
        container: className,
        paper: classes.dialogPaper,
      }}
    >
      {title && (
        <DialogTitle className={classes.title}>
          {title}
        </DialogTitle>
      )}

      {children}
    </DialogExt>
  )
}

Dialog.Actions = Actions
Dialog.Content = DialogContent

Dialog.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  children: PropTypes.node,
}

Dialog.defaultProps = {
  className: null,
  title: null,
  open: false,
  children: null,
}

export default Dialog
