import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { PlainEventHandler } from '../../../interfaces/types'

import useStyles from './useStyles'

export type NoInternetErrorProps = {
  onRetry: PlainEventHandler
}

const NoInternetError = ({
  onRetry,
}: NoInternetErrorProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      maxWidth={500}
    >
      <FontAwesomeIcon
        className={classes.icon}
        icon='exclamation-circle'
      />

      <Typography className={classes.text} color='secondary' variant='h6' align='center'>
        {t('main:no_internet_available')}
      </Typography>

      <Button
        variant='contained'
        size='large'
        color='secondary'
        className={classes.button}
        onClick={onRetry}
      >
        <FontAwesomeIcon icon='sync' className={classes.buttonIcon} />
        {t('main:retry_no_internet')}
      </Button>
    </Box>
  )
}

NoInternetError.propTypes = {
  onRetry: PropTypes.func,
}

NoInternetError.defaultProps = {
  onRetry: () => null,
}

export default NoInternetError
