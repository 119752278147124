import React from 'react'
import { useTranslation } from 'react-i18next'

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './styles'

export interface RegionModeActionsProps extends WithStyles<typeof styles> {
  onReleaseDrivers?: () => void | Promise<void>
}

const RegionModeActions: React.FC<RegionModeActionsProps> = ({
  classes, onReleaseDrivers,
}) => {
  const { t } = useTranslation()

  return (
    <Box className={classes.container}>
      <Button
        color='primary'
        variant='contained'
        className={classes.button}
        onClick={onReleaseDrivers}
      >
        <FontAwesomeIcon className={classes.icon} icon='check' />
        {t('main:release_drivers')}
      </Button>
    </Box>
  )
}

export default withStyles(styles)(RegionModeActions)
