import { Action } from 'redux'
import { ModeResponse } from '../../../interfaces/models/modeResponse'
import { SortMode } from '../../../utils/constants/enum'
import { createActionName } from '../../../utils/redux/createActionName'
import { FAILED, LOAD, SUCCESS, UPDATE } from '../../../utils/redux/defaultActionNames'
import { ApiAction, ApiFailureAction, ApiResponseAction } from '../../middleware/handleApiRequests/actions'

const actionNamespace = 'MODE'

export const MODE = createActionName(actionNamespace, 'MODE')
export const MODE_LOAD = MODE + LOAD
export const MODE_SUCCESS = MODE + SUCCESS
export const MODE_FAILED = MODE + FAILED
export const MODE_RETRY = `${MODE}_RETRY`

export const UPDATE_MODE = `${MODE}_${UPDATE}`
export const UPDATE_MODE_LOAD = UPDATE_MODE + LOAD
export const UPDATE_MODE_SUCCESS = UPDATE_MODE + SUCCESS
export const UPDATE_MODE_FAILED = UPDATE_MODE + FAILED

export const COMPLETE_SORTING = `${MODE}_COMPLETE_SORTING`
export const COMPLETE_SORTING_LOAD = COMPLETE_SORTING + LOAD
export const COMPLETE_SORTING_SUCCESS = COMPLETE_SORTING + SUCCESS
export const COMPLETE_SORTING_FAILED = COMPLETE_SORTING + FAILED

export const MISSED_CONNECTION_MODE = `${MODE}_MISSED_CONNECTION`
export const MISSED_CONNECTION_MODE_LOAD = MISSED_CONNECTION_MODE + LOAD
export const MISSED_CONNECTION_MODE_SUCCESS = MISSED_CONNECTION_MODE + SUCCESS
export const MISSED_CONNECTION_MODE_FAILED = MISSED_CONNECTION_MODE + FAILED

export const CAPACITY_SLOTS = `${MODE}_CAPACITY_SLOTS`
export const CAPACITY_SLOTS_LOAD = CAPACITY_SLOTS + LOAD
export const CAPACITY_SLOTS_SUCCESS = CAPACITY_SLOTS + SUCCESS
export const CAPACITY_SLOTS_FAILED = CAPACITY_SLOTS + FAILED

export const INIT_CAPACITY_SLOT_ACTIONS = `${MODE}_INIT_CAPACITY_SLOT_ACTIONS`

export const SWITCH_CAPACITY_SLOT = `${MODE}_SWITCH_CAPACITY_SLOT`
export const SWITCH_CAPACITY_SLOT_LOAD = SWITCH_CAPACITY_SLOT + LOAD
export const SWITCH_CAPACITY_SLOT_SUCCESS = SWITCH_CAPACITY_SLOT + SUCCESS
export const SWITCH_CAPACITY_SLOT_FAILED = SWITCH_CAPACITY_SLOT + FAILED

export const CLOSE_CAPACITY_SLOT = `${MODE}_CLOSE_CAPACITY_SLOT`
export const CLOSE_CAPACITY_SLOT_LOAD = CLOSE_CAPACITY_SLOT + LOAD
export const CLOSE_CAPACITY_SLOT_SUCCESS = CLOSE_CAPACITY_SLOT + SUCCESS
export const CLOSE_CAPACITY_SLOT_FAILED = CLOSE_CAPACITY_SLOT + FAILED

export const SWITCH_CLOSE_CAPACITY_SLOT = `${MODE}_SWITCH_CLOSE_CAPACITY_SLOT`
export const SWITCH_CLOSE_CAPACITY_SLOT_SUCCESS = SWITCH_CLOSE_CAPACITY_SLOT + SUCCESS
export const SWITCH_CLOSE_CAPACITY_SLOT_FAILED = SWITCH_CLOSE_CAPACITY_SLOT + FAILED

export interface LoadMode extends ApiAction<typeof MODE, { hasFetched: boolean }> {}
export interface LoadModeSuccess extends ApiResponseAction<typeof MODE_SUCCESS, ModeResponse, { hasFetched: boolean }>{}
export interface LoadModeFailed extends ApiFailureAction<typeof MODE_FAILED> {}

export interface RetryMode extends Action<typeof MODE_RETRY>{}

export interface UpdateMode extends ApiAction<typeof UPDATE_MODE, { sortMode: SortMode, password: string }> {}
export interface UpdateModeSuccess extends ApiResponseAction<typeof UPDATE_MODE_SUCCESS, any, SortMode>{}
export interface UpdateModeFailed extends ApiFailureAction<typeof UPDATE_MODE_FAILED> {}

export interface CompleteSorting extends ApiAction<typeof COMPLETE_SORTING, { password: string }> {}
export interface CompleteSortingSuccess extends ApiResponseAction<typeof COMPLETE_SORTING_SUCCESS> {}
export interface CompleteSortingFailed extends ApiFailureAction<typeof COMPLETE_SORTING_FAILED> {}

export interface MissedConnectionMode extends ApiAction<typeof MISSED_CONNECTION_MODE, { password: string }> {}
export interface MissedConnectionModeSuccess extends ApiResponseAction<typeof MISSED_CONNECTION_MODE_SUCCESS> {}
export interface MissedConnectionModeFailed extends ApiFailureAction<typeof MISSED_CONNECTION_MODE_FAILED> {}

export interface CapacitySlots extends ApiAction<typeof CAPACITY_SLOTS, { hasFetched: boolean }> {}
export interface CapacitySlotsSuccess extends ApiResponseAction<typeof CAPACITY_SLOTS_SUCCESS> {}
export interface CapacitySlotsFailed extends ApiFailureAction<typeof CAPACITY_SLOTS_FAILED> {}

export interface InitCapacitySlotActions extends Action<typeof INIT_CAPACITY_SLOT_ACTIONS> {}

export interface SwitchCapacitySlotLoad extends ApiAction<typeof SWITCH_CAPACITY_SLOT_LOAD, { capacitySlotDate: string, networkType: string, password: string  }> {}
export interface SwitchCapacitySlotSuccess extends ApiResponseAction<typeof SWITCH_CAPACITY_SLOT_SUCCESS> {}
export interface SwitchCapacitySlotFailed extends ApiFailureAction<typeof SWITCH_CAPACITY_SLOT_FAILED> {}

export interface CloseCapacitySlot extends Action<typeof CLOSE_CAPACITY_SLOT> {capacitySlot: {capacitySlotDate: string, networkType: string}}
export interface CloseCapacitySlotLoad extends ApiAction<typeof CLOSE_CAPACITY_SLOT_LOAD, { capacitySlotDate: string, networkType: string, password: string  }> {}
export interface CloseCapacitySlotSuccess extends ApiResponseAction<typeof CLOSE_CAPACITY_SLOT_SUCCESS> {}
export interface CloseCapacitySlotFailed extends ApiFailureAction<typeof CLOSE_CAPACITY_SLOT_FAILED> {}

export type Actions =
  LoadMode |
  LoadModeSuccess |
  LoadModeFailed |
  RetryMode |
  UpdateMode |
  UpdateModeSuccess |
  UpdateModeFailed |
  CompleteSorting |
  CompleteSortingSuccess |
  CompleteSortingFailed |
  CapacitySlots |
  CapacitySlotsSuccess |
  CapacitySlotsFailed |
  InitCapacitySlotActions |
  SwitchCapacitySlotLoad |
  SwitchCapacitySlotSuccess |
  SwitchCapacitySlotFailed |
  CloseCapacitySlotLoad |
  CloseCapacitySlotSuccess |
  CloseCapacitySlotFailed
