import { connect } from 'react-redux'

import * as fromAuth from '../store/core/authentication'
import { AppState } from '../store/reducer'

const mapStateToProps = (state: AppState) => ({
  isAuthenticated: fromAuth.isAuthenticated(state),
})

export default connect(mapStateToProps)
