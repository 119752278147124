import { ActionCreator } from 'redux'
import { AsyncAction, Dispatch, GetState } from '../../../utils/redux/thunk'
import { ApiAction } from '../../middleware/handleApiRequests/actions'

import { Add, Flush, QUEUE_ADD, QUEUE_FLUSH } from './actionNames'
import * as fromRequestQueue from '.'

export const createAddAction: ActionCreator<Add> = (deferred: ApiAction) => ({
  deferred, type: QUEUE_ADD,
})

export const createFlushDeferredAction: ActionCreator<AsyncAction<Flush>> =
  () => (dispatch: Dispatch, getState: GetState) => {
    const currentlyDeferred = fromRequestQueue.getDeferredActions(getState())

    if (!currentlyDeferred.length) {
      return
    }

    dispatch({ type: QUEUE_FLUSH })
    currentlyDeferred.forEach(action => dispatch(action))
  }
