import get from 'lodash/get'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: get(theme, 'navSizes.navBar'),
  },

  toolsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: get(theme, 'navSizes.navBar'),
    width: '100%',
  },

  logo: {
    width: 52,
    height: 32,
  },

  collapse: {
    width: '100%',
  },

  cogButton: {
    color: theme.palette.common.white,
    transition: theme.transitions.create('transform'),
  },

  open: {
    transform: 'rotate(180deg)',
  },

  menuButton: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    '&:first-child:not(:last-child)': {
      marginBottom: 0,
    },
  },
  logoContainer: {
    display: 'flex',

    '& > div:last-of-type' : {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginLeft: 10,

      '& > div:first-of-type' : {
        fontFamily: 'ClanPro-Bold',
        position: 'relative',
        top: -2,
      },
    },
  },
  dateLabel: {
    fontFamily: 'ClanPro-Wide, san-serif',
    fontSize: 10,
    color: '#FFF',
    position: 'relative',
    top: 2,
  },
})

export default makeStyles(styles)
