import { library } from '@fortawesome/fontawesome-svg-core'

import { definition as faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { definition as faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt'
import { definition as faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { definition as faSync } from '@fortawesome/free-solid-svg-icons/faSync'
import { definition as faRandom } from '@fortawesome/free-solid-svg-icons/faRandom'
import { definition as faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { definition as faTruck } from '@fortawesome/free-solid-svg-icons/faTruck'
import { definition as faCheck  } from '@fortawesome/free-solid-svg-icons/faCheck'

library.add(
  faChevronUp,
  faMobileAlt,
  faCog,
  faSync,
  faRandom,
  faExclamationCircle,
  faTruck,
  faCheck,
)
