import clsx from 'clsx'
import React, { memo } from 'react'
import { TriggerType, usePopperTooltip } from 'react-popper-tooltip'
import get from 'lodash/get'
// import { ChildrenArg, TooltipArg } from 'react-popper-tooltip/dist/types'

import styles from './styles.module.scss'

interface ITooltipProps extends React.HTMLAttributes<HTMLElement> {
  placement?: 'auto' | 'auto-start' | 'auto-end' | 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end' | 'left' | 'left-start' | 'left-end'
  text?: string | React.ReactNode
  trigger?: TriggerType
  offset?: [number, number]
  disabled?: boolean
}

const Tooltip = ({
  text,
  placement = 'right',
  trigger = 'hover',
  children,
  className,
  offset,
                   disabled,
}: ITooltipProps) => {

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ trigger, offset, interactive: true })

  const isTop = get(getTooltipProps(), 'data-popper-placement') === 'top'

  return (
    <>
      <div
        ref={setTriggerRef}
      >
        {children}
      </div>
      {
        (visible && !disabled) && (
          <div
            ref={setTooltipRef}
            {...getTooltipProps({ className: clsx(styles.tooltipContainer, className) })}
          >
            {text}
            <div {...getArrowProps({ className: clsx(styles.tooltipArrow, 'text'), 'data-placement': isTop ? 'top' : placement })} />
          </div>
        )
      }
    </>
  )
}

export default memo(Tooltip)
