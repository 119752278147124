import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import createSpacing from '@material-ui/core/styles/createSpacing'
import { fade } from '@material-ui/core/styles/colorManipulator'

import common from '@material-ui/core/colors/common'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'

const breakpoints = createBreakpoints({})
const spacing = createSpacing(6)
const primaryColor = '#201047'
const secondaryColor = '#ff5351'

const customVariables = {
  sortColors: {
    region: '#2CE27E',
    center: '#13D2FC',
    missedConnection: '#FF7D44',
  },
  success: fade('#00d763', 0.65),
  navSizes: {
    navBar: 66,
    drawer: 60,
  },
} as any

const theme = createMuiTheme({
  ...customVariables,
  spacing: 6,
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: common.white,
    },
    text: {
      primary: primaryColor,
      secondary: common.white,
    },
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: '"ClanPro-Wide", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 800,
      fontSize: 144,
      textTransform: 'uppercase',

      [breakpoints.down('xs')]: {
        fontSize: 44,
      },
    },
    h3: {
      fontWeight: 800,
      fontSize: 24,
      textTransform: 'uppercase',
    },
    h4: {
      fontWeight: 800,
      fontSize: 36,
      textTransform: 'uppercase',

      [breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    h5: {
      fontWeight: 800,
      fontSize: 24,
      textTransform: 'uppercase',

      [breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    h6: {
      fontWeight: 800,
      fontSize: 20,
      textTransform: 'uppercase',

      [breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: common.white,
        color: primaryColor,

        '&:hover': {
          backgroundColor: grey['50'],
        },
      },
    },

    MuiDialogContent: {
      root: {
        padding: spacing(3),

        [breakpoints.down('xs')]: {
          padding: spacing(2),
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiAppBar: {
      elevation: 0,
    },
  },
})

export default theme
