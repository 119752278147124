import get from 'lodash/get'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  content: {
    position: 'relative',
    padding: 0,
    width: 'calc(100% - 312px)',
    height: '100%',
    marginLeft: 312,

    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: get(theme, 'navSizes.navBar'),
      left: 0,
      height: `calc(100% - ${get(theme, 'navSizes.navBar') + get(theme, 'navSizes.drawer')}px)`,
      width: '100%',
      marginLeft: 0,
    },
  },

  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
})

export default makeStyles(styles)
