import { ReducerRegistry } from '../utils/redux/reducerRegistry'

import * as coreReducer from './core/reducer'
import * as entitiesReducer from './entities/reducer'
import * as featureReducer from './features/reducer'

export interface AppState {
  core: coreReducer.State
  entities: entitiesReducer.State
  features: featureReducer.State
}

export type CombinedActions = coreReducer.CombinedActions
  & entitiesReducer.CombinedActions
  & featureReducer.CombinedActions

const registry = new ReducerRegistry<AppState, CombinedActions>()

registry.add('core', coreReducer.reducer)
registry.add('entities', entitiesReducer.reducer)
registry.add('features', featureReducer.reducer)

export default registry.combine()
