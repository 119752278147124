import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  radioButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 36,
    minHeight: 36,
    height: 24,
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(
      ['border-color'],
      { duration: theme.transitions.duration.standard },
    ),
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      minWidth: 24,
      minHeight: 24,
      height: 24,
    },
  },

  selected: {
    borderColor: theme.palette.secondary.main,
  },

  inner: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    height: `calc(100% - ${theme.spacing(2)}px)`,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,

    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(1)}px)`,
      height: `calc(100% - ${theme.spacing(1)}px)`,
    },
  },
})

export default makeStyles(styles)
