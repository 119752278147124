import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  dialogActions: {
    padding: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& > button:first-of-type': {
      marginRight: '10px',
    },

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '& > button:first-of-type': {
        width: '100%',
        color: '#220C4A',
      },
    },

    '&.switchCapacity': {
      flexDirection: 'row-reverse',
      justifyContent: 'end',

      '& > button:last-of-type': {
        marginRight: '10px',
      },

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
        flexDirection: 'column-reverse',
        alignItems: 'center',
        justifyContent: 'center',

        '& > button:last-of-type': {
          width: '100%',
        },
      },
    },
  },
})

export default makeStyles(styles)
