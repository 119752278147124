import { CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import NoInternetError from '../../components/Base/NoInternetError'
import CenterModeActions from '../../components/Overview/CenterModeActions'

import CurrentMode from '../../components/Overview/CurrentMode'
import RegionModeActions from '../../components/Overview/RegionModeActions'
import { PlainEventHandler } from '../../interfaces/types'
import { SortMode } from '../../utils/constants/enum'
// import SwitchCapacityError from '../../components/Base/SwitchCapacityError';
import CloseCapacityError from '../../components/Base/CloseCapacityError';

export type ModeViewProps = {
  mode: SortMode
  hasFetchedMode: boolean
  hasError: boolean
  switchCapacityError?: null|string
  closeCapacityError?: null|string
  onCompleteSorting: PlainEventHandler
  onStartRefresh: PlainEventHandler
  onShowChuteList: PlainEventHandler
  onRetry: PlainEventHandler
  onCapacitySlotSwitch: (capacitySlotDate: string, networkType: string) => void
  onCloseCapacitySlot: PlainEventHandler
  selectedCapacitySlot?:  {networkType?: string, capacitySlotDate?: string}
}

const ModeView = ({
  mode, hasFetchedMode, hasError, switchCapacityError, closeCapacityError, onCloseCapacitySlot,
  onCompleteSorting, onStartRefresh, onShowChuteList, onRetry, onCapacitySlotSwitch, selectedCapacitySlot,
}: ModeViewProps) => (
  <CurrentMode mode={mode} onCapacitySlotSwitch={onCapacitySlotSwitch} onCloseCapacitySlot={onCloseCapacitySlot} selectedCapacitySlot={selectedCapacitySlot}>
    {hasFetchedMode && mode === SortMode.region && !hasError && (
      <RegionModeActions onReleaseDrivers={onCompleteSorting}/>
    )}

    {hasFetchedMode && mode === SortMode.center && !hasError && (
      <CenterModeActions
        onRefresh={onStartRefresh}
        onShowChuteList={onShowChuteList}
      />
    )}

    {!hasFetchedMode && (
      <CircularProgress color='secondary' size={60} />
    )}

    {/* {
      switchCapacityError ? <SwitchCapacityError errorMessage={switchCapacityError} /> : (hasFetchedMode && hasError) && (
        <NoInternetError onRetry={onRetry} />
      )
    } */}

    {
      closeCapacityError ? <CloseCapacityError errorMessage={closeCapacityError} /> : (hasFetchedMode && hasError) && (
        <NoInternetError onRetry={onRetry} />
      )
    }
    {}
  </CurrentMode>
)

ModeView.propTypes = {
  mode: PropTypes.oneOf(Object.values(SortMode)),
  hasFetchedMode: PropTypes.bool,
  hasError: PropTypes.bool,
  switchCapacityError: PropTypes.string,
  onStartRefresh: PropTypes.func,
  onShowChuteList: PropTypes.func,
  onRetry: PropTypes.func,
  onCapacitySlotSwitch: PropTypes.func,
  onCloseCapacitySlot: PropTypes.func,
} as any

ModeView.defaultProps = {
  mode: SortMode.unknown,
  hasFetchedMode: false,
  hasError: false,
  switchCapacityError: null,
  onStartRefresh: () => null,
  onShowChuteList: () => null,
  onRetry: () => null,
  onCapacitySlotSwitch: () => {},
  onCloseCapacitySlot: () => {},
} as any

export default ModeView
