import Button from '@material-ui/core/Button'
import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { PlainEventHandler } from '../../../../interfaces/types'
import Dialog from '../../../Base/Dialog'

import useStyles from './useStyles'

export type ButtonProps = {
  variant?: 'outlined' | 'contained' | 'text',
  color?: 'secondary' | 'success' | 'inherit',
  disabled?: boolean
  text?: string
  className?: string
}

export type ConfirmationActionsProps = {
  disabled: boolean
  label: string | ReactNode
  classN?: string
  confirmButtonProps?: ButtonProps
  closeButtonProps?: ButtonProps
  onConfirm: PlainEventHandler
  onClose: PlainEventHandler
}

const ConfirmationActions = ({
  disabled, label, classN,
  confirmButtonProps = {
    color: 'inherit',
  },
  closeButtonProps = {
    color: 'inherit',
  }, onConfirm, onClose,
}: ConfirmationActionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const isCloseWithSuccessStyle = closeButtonProps.color === 'success';
  const isConfirmWithSecondaryStyle = confirmButtonProps.color  === 'secondary';

  return (
    <Dialog.Actions className={classN}>
      <Button
        variant={closeButtonProps && closeButtonProps.variant}
        size='large'
        // color='secondary'
        onClick={onClose}
        className={clsx(closeButtonProps.className, isCloseWithSuccessStyle ? classes.confirmButton : undefined)}
      >
        {closeButtonProps.text ? closeButtonProps.text : t('main:close')}
      </Button>
      <Button
        variant={confirmButtonProps && confirmButtonProps.variant}
        className={clsx(confirmButtonProps.className, isConfirmWithSecondaryStyle ? undefined : classes.confirmButton)}
        color='secondary'
        size='large'
        disabled={disabled || confirmButtonProps.disabled}
        onClick={onConfirm}
        // fullWidth={true}
        // className={classes.confirmButton}
      >
        {label}
      </Button>
    </Dialog.Actions>
  )
}

ConfirmationActions.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classN: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}

ConfirmationActions.defaultProps = {
  disabled: false,
  label: null,
  classN: null,
  onConfirm: () => null,
  onClose: () => null,
}

export default ConfirmationActions
