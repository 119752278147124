import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Typography from '@material-ui/core/Typography'

import { ChildrenProp } from '../../../interfaces/props'

import useStyles from './useStyles'

export type LandscapeModalProps = ChildrenProp

const LandscapeModal = ({
  children,
}: LandscapeModalProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <div className={classes.modal}>
        <FontAwesomeIcon icon='mobile-alt' className={classes.icon} />

        <Typography variant='body1' color='textSecondary'>
          {t('main:no_landscape_support')}
        </Typography>
      </div>

      <span className={classes.blurredContent}>
        {children}
      </span>
    </>
  )
}

LandscapeModal.propTypes = {
  children: PropTypes.node,
}

LandscapeModal.defaultProps = {
  children: null,
}

export default LandscapeModal
