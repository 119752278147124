import get from 'lodash/get'

import { State as CoreState } from '../../store/core/reducer'
import { State as FeatureState } from '../../store/features/reducer'
import { State as EntitiesState } from '../../store/entities/reducer'
import { AppState } from '../../store/reducer'

const createNestedSelector = <TState>(...pathParts: string[]) => {
  const fullName = pathParts.join('.')

  return (state: AppState): TState => get(state, fullName, {})
}

export const createCoreSelector = <TState>(coreFeatureName: keyof CoreState) =>
  createNestedSelector<TState>('core', coreFeatureName)

export const createFeatureSelector = <TState>(featureName: keyof FeatureState) =>
  createNestedSelector<TState>('features', featureName)

export const createEntitySelector = <TState>(entityName: keyof EntitiesState) =>
  createNestedSelector<TState>('entities', entityName)
