import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { DriverChute } from '../../../interfaces/models/driverChute'
import { OpenProp } from '../../../interfaces/props'
import { PlainEventHandler } from '../../../interfaces/types'

import Dialog from '../../Base/Dialog'

import useStyles from './useStyles'

export type ChuteDialogProps = OpenProp & {
  chutes: DriverChute[]
  onClose: PlainEventHandler
}

const ChuteDialog = ({
  open, chutes, onClose,
}: ChuteDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      title={t('main:chute_list')}
    >
      <Dialog.Content className={classes.contentPanel}>
        <List className={classes.list}>
          <Divider />
          {chutes.length === 0 && (
            <ListItem divider={true} className={classes.item}>
              <ListItemText
                primary={t('main:no_drivers_assigned_yet')}
                primaryTypographyProps={{ className: classes.itemText, variant: 'body2', align: 'center' }}
              />
            </ListItem>
          )}

          {useMemo(() => chutes.map(chute => (
            <ListItem
              divider={true}
              className={classes.item}
              key={`${chute.driver}-${chute.chute}`}
            >
              <ListItemText
                primary={chute.driver}
                primaryTypographyProps={{ className: classes.itemText, variant: 'body2' }}
              />
              <ListItemSecondaryAction className={classes.itemAction}>
                <Typography className={classes.itemText} variant='body2'>
                  {chute.chute}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          )),      [chutes, classes])}
        </List>
      </Dialog.Content>

      <Dialog.Actions>
        <Button
          size='large'
          variant='text'
          color='secondary'
          fullWidth={true}
          onClick={onClose}
        >
          {t('main:close')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}

ChuteDialog.propTypes = {
  open: PropTypes.bool,
  chutes: PropTypes.arrayOf(PropTypes.shape(({
    driverName: PropTypes.string,
    chuteNumber: PropTypes.number,
  }))),
  onClose: PropTypes.func,
}

ChuteDialog.defaultProps = {
  open: false,
  chutes: [],
  onClose: () => null,
}

export default ChuteDialog
