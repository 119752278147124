import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'
import { createEntitySelector } from '../../../utils/redux/selectors'
import { State } from './reducer'

import * as selectors from './selectors'
import { CapacitySlot } from '../../../interfaces/models/capacitySlot';
import moment from 'moment';

const selectEntity = createEntitySelector<State>('sortMode')

export const isLoading = createSelector(
  selectEntity,
  selectors.selectIsLoading,
)
export const isWorking = createSelector(
  selectEntity,
  selectors.selectIsWorking,
)
export const hasFetched = createSelector(
  selectEntity,
  selectors.selectHasFetched,
)

export const getMode = createSelector(
  selectEntity,
  selectors.selectMode,
)
export const getLastError = createSelector(
  selectEntity,
  selectors.selectLastError,
)

export const getSwitchCapacityError = createSelector(
  selectEntity,
  selectors.selectSwitchCapacitySlotError,
)

export const getCloseCapacityError = createSelector(
  selectEntity,
  selectors.selectCloseCapacitySlotError,
)

export const isCompleting = createSelector(
  selectEntity,
  selectors.selectIsCompleting,
)

export const getCapacitySlots = createSelector(
  selectEntity,
  selectors.selectCapacitySlots,
)

export const getCurrentCapacitySlot = createSelector(
  selectEntity,
  state => ({
    networkType: state.networkType,
    capacitySlotDate: state.capacitySlotDate,
  })
)

export const getNextAvailableCapacitySlot = createSelector(
  getCapacitySlots,
  getCurrentCapacitySlot,
  (capacitySlots: CapacitySlot[], currentCapacitySlot: {
    networkType: any,
    capacitySlotDate: any
  }) => capacitySlots.find(
    // tslint:disable-next-line:ter-arrow-parens
    (capacitySlot) =>
      moment(capacitySlot.date).isAfter(currentCapacitySlot.capacitySlotDate, 'day') &&
      !capacitySlot.isClosed
  )
)

export const getOpenCapacitySlots = createSelector(
  getCapacitySlots,
  (capacitySlots: CapacitySlot[]) => sortBy(capacitySlots,
                                            ['date', 'networkType'])
)

export const getIsCapacityActionsActive = createSelector(
  selectEntity,
  selectors.selectIsCapacityActionsActive,
)

export const getIsCapacityActionsLoading = createSelector(
  selectEntity,
  selectors.selectIsCapacityActionsLoading,
)

export const getIsCapacityActionsSucceeded = createSelector(
  selectEntity,
  selectors.selectIsCapacityActionsSucceeded,
)

export const getIsCurrentCapacitySlotClosed = createSelector(
  selectEntity,
  selectors.selectIsCurrentCapacitySlotClosed,
)
