import { schema, normalize } from 'normalizr'

import { DriverChute } from '../../../interfaces/models/driverChute'

const driverChuteSchema = new schema.Entity('driverChutes', {}, { idAttribute: 'chute' })

export default (data: DriverChute[]) => {
  const {
    result: keys,
    entities: { driverChutes: normalizedData },
  } = normalize(data, [driverChuteSchema])

  return [
    keys || [],
    normalizedData || {},
  ]
}
