import { createSelector } from 'reselect'
import { countdownTime } from '../../../utils/constants'
import { getTimestampDiff } from './helpers'
import { State } from './reducer'

export const selectActiveCountDown = (state: State) => state.activeCountdown
export const selectTimestampDiff = (state: State) => getTimestampDiff(state.lastCountdownStamp)

export const selectCountdownSeconds = createSelector(
  selectTimestampDiff,
  difference => difference < countdownTime
                ? countdownTime - difference
                : countdownTime,
)
