import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import get from 'lodash/get'

import InputBase, { InputBaseProps } from '@material-ui/core/InputBase'

import { SortMode } from '../../../utils/constants/enum'

import useStyles from './useStyles'

export type FieldProps = {
  color: SortMode | 'default'
} & InputBaseProps

const Field = ({ color, ...props }: FieldProps) => {
  const classes = useStyles()

  return (
    <InputBase
      {...props}
      classes={{
        ...props.classes,
        root: classes.inputContainer,
        input: clsx(classes.input, get(classes, color)),
      }}
    />
  )
}

Field.propTypes = {
  color: PropTypes.oneOf(['default', ...Object.values(SortMode)]),
}

Field.defaultProps = {
  color: 'default',
}

export default Field
