import { default as i18nNext } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'

import { isDevelopment } from '../utils/constants'

import englishMain from '../localizations/en/main.json'
import dutchMain from '../localizations/nl/main.json'

const configureMomentJS = () => {
  // Localize Moment JS
  const locale = i18nNext.languages[1]
  moment.locale(locale)
}

const i18nOptions = {
  resources: {
    en: { main: englishMain },
    nl: { main: dutchMain },
  },
  fallbackLng: 'nl',
  debug: isDevelopment,
  interpolation: {
    escapeValue: false,
  },
}

export const i18n = i18nNext
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(i18nOptions, configureMomentJS)
