import { createSelector } from 'reselect'
import { createEntitySelector } from '../../../utils/redux/selectors'
import { State } from './reducer'

import * as selectors from './selectors'

const selectEntity = createEntitySelector<State>('driverChutes')

export const isLoading = createSelector(
  selectEntity,
  selectors.selectIsLoading,
)
export const hasFetched = createSelector(
  selectEntity,
  selectors.selectHasFetched,
)

export const getIdentifiers = createSelector(
  selectEntity,
  selectors.selectIdentifiers,
)
export const getData = createSelector(
  selectEntity,
  selectors.selectData,
)
export const getLastError = createSelector(
  selectEntity,
  selectors.selectLastError,
)

export const getDriverChutes = createSelector(
  getIdentifiers,
  getData,
  (ids, data) => ids.map(id => data[id])
)
