import get from 'lodash/get'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import { SortMode } from '../../../../utils/constants/enum'

const styles = (theme: Theme) => createStyles({
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(6),
    flex: 1,
    minHeight: 138,
    borderRadius: 0,
    boxShadow: 'none',

    [theme.breakpoints.down('sm')]: {
      minHeight: 60,
      padding: `0 ${theme.spacing(4)}px`,
    },
  },

  [SortMode.region]: {
    backgroundColor: get(theme, 'sortColors.region'),

    '&:hover': {
      backgroundColor: darken(get(theme, 'sortColors.region', '#fff'), 0.1),
    },
  },

  [SortMode.center]: {
    backgroundColor: get(theme, 'sortColors.center'),

    '&:hover': {
      backgroundColor: darken(get(theme, 'sortColors.center', '#fff'), 0.1),
    },
  },

  [SortMode.missedConnection] : {
    backgroundColor: get(theme, 'sortColors.missedConnection'),
    '&:hover': {
      backgroundColor: darken(get(theme, 'sortColors.missedConnection', '#fff'), 0.1),
    },
  },

  text: {
    lineHeight: 1,
    textAlign: 'left',
  },

  radioButton: {
    marginRight: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(3),
    },
  },
})

export default makeStyles(styles)
