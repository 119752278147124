import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import React from 'react';

import { OpenProp } from '../../../interfaces/props';
import { PlainEventHandler } from '../../../interfaces/types';
import { countdownTime } from '../../../utils/constants';
import { SortMode } from '../../../utils/constants/enum';

import { capitalize } from '../../../utils/strings';
import ConfirmationDialog from '../ConfirmationDialog';

import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  getCurrentCapacitySlot,
  getIsCapacityActionsLoading,
  getOpenCapacitySlots,
} from '../../../store/entities/sortMode';
import { mapServerText } from '../../../utils/strings/mapServerText';
import useStyles from './useStyles';

export type CloseCapacitySlotDialogProps = OpenProp & {
  isLoading: boolean;
  closeCapacityError: null | string | undefined;
  showErrorMessage?: null | boolean;
  selectedCapacitySlot?: { networkType?: string; capacitySlotDate?: string };
  onSubmit: (password: string) => void;
  onCapacitySlotSwitch: (capacitySlotDate: string, networkType: string) => void;
  onClose: PlainEventHandler;
};

const CloseCapacitySlotDialog = ({
  open,
  closeCapacityError,
  showErrorMessage,
  onCapacitySlotSwitch,
  onSubmit,
  onClose,
}: CloseCapacitySlotDialogProps) => {
  const classes = useStyles();
  const isLoading = useSelector(getIsCapacityActionsLoading);
  const capacitySlots = useSelector(getOpenCapacitySlots);
  const { networkType, capacitySlotDate } = useSelector(getCurrentCapacitySlot);

  const nextAvailableCapacity = capacitySlots.find(
    // tslint:disable-next-line:ter-arrow-parens
    (capacitySlot) =>
      moment(capacitySlot.date).isAfter(capacitySlotDate, 'day') &&
      !capacitySlot.isClosed
  );

  const capacitySlotDateText = `${moment(capacitySlotDate).format(
    'MMMM DD'
  )} - ${capitalize(mapServerText(networkType))}`;

  return (
    <ConfirmationDialog
      open={open}
      isLoading={isLoading}
      isPassword={true}
      title={'Close this capacity'}
      color={SortMode.region}
      message={
        <>
          <Trans
            i18nKey={'main:close_capacity_dialog_confirmation'}
            tOptions={{ countdownTime, capacitySlotDateText }}
          >
            <span />
          </Trans>
          <div className={classes.nextCapacityLabel}>
            Next Capacity Slot: {' '}
            <b>
              {!!nextAvailableCapacity ? (
                <span>
                  {`${moment(nextAvailableCapacity.date).format(
                    'MMMM DD'
                  )} - ${capitalize(
                    mapServerText(nextAvailableCapacity.networkType)
                  )}`}
                </span>
              ) : <span style={{ color: 'red' }}>No more available capacity slots</span>
              }
            </b>
          </div>
        </>
      }
      confirmButtonLabel={`Close ${capacitySlotDateText}`}
      confirmButtonProps={{
        variant: 'text',
        color: 'secondary',
        className: classes.dialogConfirmButtons,
      }}
      closeButtonProps={{
        variant: 'contained',
        color: 'success',
        text: 'Go back',
        className: classes.dialogConfirmButtons,
      }}
      onConfirm={onSubmit}
      onClose={onClose}
      errorMessage={closeCapacityError}
      showErrorMessage={showErrorMessage}
    />
  );
};

CloseCapacitySlotDialog.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onCapacitySlotSwitch: PropTypes.func,
  capacitySlotDateText: PropTypes.string,
  closeCapacityError: PropTypes.string,
  showErrorMessage: PropTypes.bool,
};

CloseCapacitySlotDialog.defaultProps = {
  open: false,
  isLoading: false,
  onSubmit: () => null,
  onClose: () => null,
  onCapacitySlotSwitch: () => null,
};

export default CloseCapacitySlotDialog;
