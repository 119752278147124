import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const spacingMultiples = 8
const spacingMultiplesMobile = 5

const styles = (theme: Theme) => createStyles({
  gradientContainer: {
    position: 'relative',
    padding: theme.spacing(spacingMultiples),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(spacingMultiplesMobile),
    },
  },

  gradientHost: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: theme.spacing(spacingMultiples),
    bottom: theme.spacing(spacingMultiples),
    right: theme.spacing(spacingMultiples),
    left: theme.spacing(spacingMultiples),
    opacity: 0.7,
    filter: 'blur(42px)',

    [theme.breakpoints.down('xs')]: {
      filter: 'blur(30px)',
      top: theme.spacing(spacingMultiplesMobile),
      bottom: theme.spacing(spacingMultiplesMobile),
      right: theme.spacing(spacingMultiplesMobile),
      left: theme.spacing(spacingMultiplesMobile),
    },
  },

  gradientUpper: {
    flex: 1,
    background: `
      linear-gradient(220deg, #201047, #13D2FC 24%, #00D763 47%, #FFD65A 72%, #FF5351 100%)
    `,
  },

  gradientLower: {
    flex: 1,
    background: `
      linear-gradient(220deg, #FF5351, #FFD65A 24%, #00D763 47%, #13D2FC 72%, #201047 100%)
    `,
  },

  paper: {
    position: 'relative',
    width: 556,
    height: 314,

    [theme.breakpoints.down('xs')]: {
      width: 300,
      height: 260,
    },
  },
})

export default makeStyles(styles)
