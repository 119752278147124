import React from 'react'
import { render } from 'react-dom'

import App from './App'
import AppProvider from './AppProvider'

import './globalStyles/main.css'
import './globalStyles/fonts.css'
import './globalStyles/animations.css'
import './components/Base/.raw/scss/_fonts.scss'

import { register } from './registerServiceWorker'

render(
  (
    <AppProvider>
      <App />
    </AppProvider>
  ),
  document.querySelector('#root'),
)

register()
