import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Box from '@material-ui/core/Box'

import { ChildrenProp, ClassNameProp } from '../../../../interfaces/props'

import useStyles from './useStyles'

export type ActionsProps = ChildrenProp & ClassNameProp

const Actions = ({
  children, className,
}: ActionsProps) => {
  const classes = useStyles()

  return (
    <Box
      flex='0 0 auto'
      display='flex'
      className={clsx(classes.dialogActions, className)}
    >
      {children}
    </Box>
  )
}

Actions.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Actions.defaultProps = {
  className: null,
  children: null,
}

export default Actions
