import AWS from 'aws-sdk/global'
import get from 'lodash/get'
import { ActionCreator } from 'redux'

import { identityPoolId } from '../../../utils/constants/AWS'
import { AsyncAction, Dispatch, GetState } from '../../../utils/redux/thunk'
import { createFlushDeferredAction } from '../requestQueue/actions'

import * as fromAuth from '.'
import { LOGOUT, Logout, UPDATE_AWS_CREDS, UPDATE_TOKEN, UpdateAwsKeys, UpdateToken } from './actionNames'

export const createUpdateTokenAction: ActionCreator<UpdateToken> = (
  token: string,
  expires: Date,
  email: string,
) => ({
  type: UPDATE_TOKEN,
  payload: { token, expires, email },
})

export const createUpdateAwsKeysAction: ActionCreator<AsyncAction<UpdateAwsKeys>> = (
  accessKeyId: string,
  secretAccessKey: string,
  sessionToken: string,
  expires: Date,
) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_AWS_CREDS,
    payload: {
      accessKeyId,
      secretAccessKey,
      sessionToken,
      expires,
    },
  })

  dispatch(createFlushDeferredAction())
}

export const createLogoutAction: ActionCreator<Logout> = () => {
  /*eslint-disable no-undef*/
  gapi.auth2.getAuthInstance().signOut()
  /*eslint-enable no-undef*/

  return ({
    type: LOGOUT,
  })
}

export const doStartGoogleAuthTicker: ActionCreator<AsyncAction> =
  (client: gapi.auth2.GoogleAuth) => (dispatch: Dispatch, getState: GetState) => {
    client.currentUser.listen((user) => {
      const authResponse = user.getAuthResponse(true)
      const profile = user.getBasicProfile()
      const email = profile && profile.getEmail()

      // auth details not set when logged out
      if (authResponse) {
        dispatch(doCognitoLoginAction({ ...authResponse, email }) as any)
      } else {
        const isAuthenticated = fromAuth.isAuthenticated(getState())

        if (isAuthenticated) {
          dispatch(createLogoutAction())
        }
      }
    })
  }

export const doCognitoLoginAction: ActionCreator<AsyncAction<UpdateAwsKeys>> =
  (authResult: gapi.auth2.AuthResponse) => async (dispatch: Dispatch) => {
    dispatch(createUpdateTokenAction(
      authResult.id_token,
      new Date(authResult.expires_at),
      get(authResult, 'email'),
    ))

    const credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identityPoolId,
      Logins: { 'accounts.google.com': authResult.id_token },
    })

    await credentials.getPromise()

    dispatch(createUpdateAwsKeysAction(
      credentials.accessKeyId,
      credentials.secretAccessKey,
      credentials.sessionToken,
      credentials.expireTime,
    ))
  }
