import { ReducerRegistry } from '../../utils/redux/reducerRegistry'

import * as overviewReducer from './overview/reducer'
import * as overviewAction from './overview/actionNames'

export interface State {
  overview: overviewReducer.State
}

export type CombinedActions =
  overviewAction.Actions

const registry = new ReducerRegistry<State, CombinedActions>()

registry.add('overview', overviewReducer.reducer)

export const reducer = registry.combine()
