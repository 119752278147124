import get from 'lodash/get'

import { ModeResponse } from '../../../interfaces/models/modeResponse'
import { SortMode } from '../../../utils/constants/enum'

export const mapResponseToMode = (response: ModeResponse): SortMode => {
  switch (get(response, 'mode')) {
    case 'CNT_1':
    case 'CNT_2':
      return SortMode.center
    case 'REGION':
      return SortMode.region
    case 'MISSED_CONNECTION':
      return SortMode.missedConnection
    default:
      return SortMode.unknown
  }
}

export const mapSortModeToServerMode = (mode: SortMode): 'CNT_1' | 'REGION' =>
  mode === SortMode.region ? 'REGION' : 'CNT_1'
