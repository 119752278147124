import { State } from './reducer'

export const selectIsLoading = (state: State) => state.isLoading
export const selectIsWorking = (state: State) => state.isWorking
export const selectHasFetched = (state: State) => state.hasFetched
export const selectMode = (state: State) => state.mode
export const selectLastError = (state: State) => state.lastError
export const selectIsCompleting = (state: State) => state.isCompleting
export const selectCapacitySlots = (state: State) => state.capacitySlots
export const selectCurrentNetworkType = (state: State) => state.networkType
export const selectCurrentCapacitySlotDate = (state: State) => state.capacitySlotDate
export const selectIsCurrentCapacitySlotClosed = (state: State) => state.currentCapacitySlotClosed
export const selectSwitchCapacitySlotError = (state: State) => state.switchCapacityError
export const selectCloseCapacitySlotError = (state: State) => state.closeCapacityError
export const selectIsCapacityActionsActive = (state: State) => state.capacityActionsActive
export const selectIsCapacityActionsLoading = (state: State) => state.capacityActionsLoading
export const selectIsCapacityActionsSucceeded = (state: State) => state.capacityActionsSucceeded
