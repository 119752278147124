import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  button: {
    boxShadow: 'none',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexBasis: '100%',
    },

    '&:first-child': {
      marginRight: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
  },

  icon: {
    marginRight: theme.spacing(2),
  },
})

export default makeStyles(styles)
