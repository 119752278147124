import { createStyles, Theme } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({
  targetMode: {
    textTransform: 'uppercase',
    fontWeight: 800,
  },
})

export default styles
