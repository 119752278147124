import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { ClassNameProp } from '../../../interfaces/props'
import { PlainEventHandler } from '../../../interfaces/types'

import useStyles from './useStyles'

export type CountdownTimerProps = ClassNameProp & {
  seconds: number,
  onCountdownDone: PlainEventHandler
}

const CountdownTimer = ({
  seconds, onCountdownDone,
}: CountdownTimerProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [current, setCurrent] = useState(seconds)

  useEffect(
    () => {
      let count = seconds

      const timeoutRef = setInterval(
        () => {
          if (count === 1) {
            clearInterval(timeoutRef)
            onCountdownDone()
          }

          setCurrent(count -= 1)
        },
        1000,
      )

      return () => clearInterval(timeoutRef)
    },
    [seconds, onCountdownDone],
  )

  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      p={2}
      marginBottom={3}
    >
      <Typography variant='h1' className={classes.timer}>
        {current}
      </Typography>
      <Typography variant='h3' className={classes.seconds}>
        {t('main:second', { count: current })}
      </Typography>
    </Box>
  )
}

CountdownTimer.propTypes = {
  seconds: PropTypes.number.isRequired,
  onCountdownDone: PropTypes.func,
}

CountdownTimer.defaultProps = {
  onCountdownDone: () => null,
}

export default CountdownTimer
