import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  targetMode: {
    textTransform: 'uppercase',
    fontWeight: 800,
  },
  capacitySwitchDropdown: {
    margin: '30px 0',
    '& ul:last-of-type': {
      width: 'calc(100% - 35px)',

      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 25px)',
      },
    },
  },
  capacitySwitchMenuItem: {
    width: '100%',

    '& > div' : {
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
  },

  capacitySlotButtonSelector: {
    display: 'flex',

    '& > div:nth-child(even) > div > button' : {
      marginLeft: 8,
    },

    '& > div > div > button > div' : {
      position: 'relative',
      top: 2,
      left: -4,
    },

    '& > div > div > button > div > svg' : {
      height: 18,
      width: 18,
    },
  },

  dialogConfirmButtons: {
    fontSize: 12,
  },

  nextCapacityLabel: {
    position: 'relative',
    top: 24,
    marginBottom: 32,

    '& > span' : {
      fontWeight: 'bold',
    },
  },
})

export default makeStyles(styles)
